@import './vars.scss';

@mixin mixinPrimaryButton(
  $padding: '',
  $bgcolor: '',
  $color: '',
  $border: '',
  $borderRadius: '',
  $width: '',
  $height: '',
  $weight: ''
) {
  padding: #{$padding};
  background-color: #{$bgcolor};
  color: #{$color};
  border: #{$border};
  border-radius: #{$borderRadius};
  font-weight: #{$weight};
  width: #{$width};
  height: #{$height};
}

// Auth
@mixin mixinAuthTitle {
  font-weight: 700;
  color: $textTitle;
  font-size: 2.25rem;
}

@mixin mixinAuthFormWrapper {
  width: 100%;
  max-width: 100%;
  background-color: $backgroundSecondary;
}

@mixin mixinAuthContainer($width: '', $padding: '') {
  width: #{$width};
  padding: #{$padding};
}

@mixin mixinAuthLogoWrapper {
  width: 62.5rem;
  max-width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $backgroundPrimary;
}
