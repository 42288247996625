@import './media.scss';
@import './mixins.scss';
@import './vars.scss';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  cursor: default;
  font-family: $basicFont;
  width: 100%;
}

p {
  white-space: nowrap;
}

html {
  font-size: 1rem;
}

button,
input {
  font-family: $basicFont;
}

section {
  position: relative;
}

a {
  display: inline-block;
  text-decoration: none;
}

textarea,
button,
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: none;
  outline: none;

  &[disabled],
  &[readonly] {
    cursor: default;
    opacity: 0.7;
  }
}

button {
  cursor: pointer;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  width: 0.375rem;
  scrollbar-color: #ffca00;
  scroll-behavior: smooth;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: $backgroundGrayExtraLight;
  height: 1.875rem;
  border-radius: 3.125rem;
}

@media(max-width: 480px) {
  .Toastify__toast {
    min-height: 15px !important;
  }
}
