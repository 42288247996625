@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $graySix;
  width: 100%;

  &__container {
    padding: 1.875rem 1rem 0;
    position: relative;
    max-width: 100%;
    @include mixinAuthContainer(72.125rem);

    & .wrapper__title {
      margin-bottom: 1.125rem;

      & .title {
        color: $accentDark;
        font-size: 1.875rem;
        font-weight: 600;
      }
    }

    & form {
      position: relative;
    }

    & .back {
      display: flex;
      align-items: center;
      margin-bottom: 1.375rem;

      &_icon {
        background-color: $buttonPrimary;
        width: 2.25rem;
        height: 2.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        margin-right: 0.75rem;
      }

      &_text {
        & p {
          font-size: 1.25rem;
          font-weight: 500;
          color: $accentDark;
        }
      }

      @media (max-width: 768px) {
        padding: 1.875rem 1rem 0;
        & + div {
          padding: 0 1rem;
          margin-bottom: 14rem;
        }
      }
    }

    & .wrapper__detail {
      display: flex;
      margin-bottom: 1.125rem;

      .detail {
        background-color: $white;
        border-radius: 1rem;
        padding: 1.5rem;
        border: 1px solid $grayFive;
        position: relative;
        width: 1rem;
        flex: 1;
        z-index: 2;
        margin-right: 1.5rem;

        & .progress {
          border-bottom: 2px dashed $grayFour;
          padding-bottom: 1rem;
          margin-bottom: 1rem;
        }

        & .payment__description {
          margin-bottom: 1rem;

          & h2 {
            font-size: 1.25rem;
            font-weight: 500;
            color: $accentDark;
            margin-bottom: 0.3rem;
          }

          &_edit {
            display: flex;
            align-items: center;

            & .description {
              overflow-y: hidden;

              &::-webkit-scrollbar {
                height: 0.3rem;
              }

              &::-webkit-scrollbar-thumb {
                // background-color: $accentYellow;
                border-radius: 3.125rem;
              }

              &::-webkit-scrollbar-track {
                border-radius: 3.125rem;
              }
            }

            & button {
              display: flex;
              margin-left: 0.6rem;
            }
          }

          & .payment__input {
            display: flex;

            & .edit__icons {
              display: flex;
              align-items: center;
              margin-left: 0.6rem;

              & .edit__icon {
                & .edit_btn {
                  display: flex;
                  height: 100%;
                }

                & .edit__mark_btn {
                  margin-right: 0.6rem;

                  & svg {
                    stroke: $accentDark;
                    stroke-width: 1px;
                    width: 20px;
                    height: 20px;
                  }
                }
              }
            }

            & .operators__items_icon_edit {
              display: flex;
              margin-left: 0.5rem;
            }
          }
        }

        & .creator {
          color: $accentDark;
          padding-bottom: 1rem;
          border-bottom: 2px dashed $grayFour;
          margin-bottom: 1rem;

          & h4 {
            font-weight: 400;
            font-size: 0.875rem;
            margin-bottom: 0.5rem;
          }

          & p {
            font-weight: 600;
            font-size: 1.5rem;
          }
        }

        & .date {
          display: flex;
          gap: 4.75rem;
          margin-bottom: 2.1875rem;

          &__item {
            & h5 {
              color: $grayThree;
              font-size: 0.875rem;
              font-weight: 400;
              margin-bottom: 0.1875rem;
            }

            & p {
              font-size: 1rem;
              color: $accentDark;
            }
          }

          @media (max-width: 480px) {
            justify-content: space-between;
            gap: 1rem;
          }
        }

        & .transactions {
          padding-bottom: 2.1875rem;
          border-bottom: 2px dashed $grayFour;

          & h4 {
            font-size: 0.875rem;
            font-weight: 400;
            margin-bottom: 0.6875rem;
            text-align: center;
          }

          &__items {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 1rem;

            & .item {
              background-color: $graySix;
              border-radius: 0.5rem;
              padding: 0.5rem 1rem;

              & h5 {
                color: $accentDark;
                font-size: 0.875rem;
                font-weight: 400;
                margin-bottom: 0.5rem;
                width: max-content;
              }

              & p {
                font-size: 1.25rem;
                font-weight: 700;
                color: $accentDark;
              }

              & .both__statuses {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                & p {
                  font-weight: 300;
                  font-size: 0.9rem;
                }
              }
            }

            @media (max-width: 768px) {
              grid-template-columns: repeat(1, 1fr);
            }
          }
        }

        & .services {
          margin: 2.0625rem 0;

          &__sort {
            margin-bottom: 2.0625rem;

            & input {
              width: 0;
              height: 0;
            }

            @media (max-width: 768px) {
              display: none;
            }
          }

          &__sort_mobile {
            margin-bottom: 2.0625rem;
            align-items: center;
            justify-content: space-between;
            display: none;

            & .filter__title {
              font-size: 1.5rem;
              font-weight: 400;
            }

            & input {
              width: 0;
              height: 0;
            }

            @media (max-width: 768px) {
              display: flex;
            }
          }

          & .filter__wrapper {
            padding: 0.3rem;
            width: 2.7rem;
            height: 2.7rem;
            display: flex;
            align-items: center;
            justify-content: center;

            & .filter__icon {
              display: flex;

              & svg {
                width: 1.6rem;
                height: 1.6rem;

                cursor: pointer;

                & path {
                  stroke-width: 0.6rem;
                  stroke: #000000;
                }
              }
            }
          }

          &__list {
            & .item {
              & .item__title_wrapper {
                margin-bottom: 1rem;
                padding-bottom: 1rem;
                border-bottom: 1px solid $grayFour;

                & .item__link {
                  display: flex;
                  align-items: center;
                  width: fit-content;
                  cursor: pointer;

                  &:hover .item__title {
                    color: $accentYellow;
                  }

                  &:hover svg path {
                    fill: $accentYellow;
                  }

                  & .item__icon {
                    display: flex;
                    align-items: center;
                    margin-left: 0.3rem;

                    & svg {
                      width: 0.8rem;
                    }

                    & svg path {
                      transition: fill 0.3s ease;
                    }
                  }
                }

                & .item__title {
                  font-weight: 700;
                  font-size: 1.5rem;
                  color: $accentDark;
                  width: fit-content;

                  & {
                    transition: color 0.2s ease;
                  }

                  &:hover {
                    color: $yellowLight;
                  }
                }
              }

              &__description {
                border-bottom: 1px solid $grayFour;
                padding-bottom: 1rem;
                margin-bottom: 1rem;
                line-height: 1.5rem;

                & .transaction__id {
                  display: flex;
                  align-items: center;
                  flex-wrap: wrap;

                  & .description__title {
                    margin-right: 0.5rem;
                    font-size: 0.875rem;
                    font-weight: 600;
                  }

                  & p {
                  }
                }

                &:last-child {
                  margin-bottom: 2.1875rem;
                }

                & .description__title {
                  font-weight: 700;
                  font-size: 1rem;
                }

                & .description__text {
                  font-weight: 500;
                  font-size: 0.875rem;
                  color: $accentDark;
                  overflow-y: hidden;

                  &::-webkit-scrollbar {
                    height: 0.5rem;
                  }
                }

                & .description__error {
                  display: flex;
                  justify-content: space-between;

                  & .wrapper__fail {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    overflow-y: hidden;

                    &::-webkit-scrollbar {
                      height: 0.5rem;
                    }
                  }

                  & p {
                    overflow-y: hidden;

                    &::-webkit-scrollbar {
                      height: 0.5rem;
                    }
                  }

                  & svg {
                    fill: $grayThree;
                    cursor: pointer;
                  }

                  & button {
                    margin-left: 1rem;

                    &:disabled {
                      cursor: default;
                      color: $grayThree;

                      & svg {
                        cursor: default;
                      }
                    }
                  }
                }

                & .status__successful {
                  & p {
                    color: $statusSuccessful;
                  }
                }

                & .status__in_process {
                  & p {
                    color: $accentYellow;
                  }
                }

                & .status__error {
                  & p {
                    color: $statusFailed;
                  }
                }

                & .description__status {
                  font-weight: 500;
                  font-size: 0.875rem;
                }

                &__inner {
                  display: flex;
                  justify-content: space-between;
                }

                & .item__description_product {
                  display: flex;

                  & h5 {
                    margin-right: 0.5rem;
                    font-size: 0.875rem;
                    font-weight: 600;
                  }
                }

                @media (max-width: 768px) {
                  display: flex;
                  flex-direction: column;

                  &__inner {
                    order: 1;
                    flex-wrap: wrap;

                    & > h5 {
                      margin-right: 0.5rem;
                    }
                  }
                }
              }

              &__description_deactivated {
                & p,
                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                  color: $grayFour;
                }

                & .title__deactivated {
                  font-weight: 300;
                }

                & .description__text {
                  color: $grayFour;
                }

                & .item__title_wrapper {
                  display: flex;
                  align-items: center;
                  border-bottom: none;
                  margin-bottom: 0;
                  padding-bottom: 0;

                  & .item__title {
                    cursor: default;
                    color: $grayFour;

                    & .title__deactivated {
                      font-weight: 300;
                    }

                    &:hover {
                      color: $grayFour;
                    }
                  }

                  & .item__icon {
                    display: flex;
                    align-items: center;
                    margin-left: 0.3rem;
                    cursor: pointer;
                    width: 16px;

                    & svg path {
                      fill: $accentDark;
                      transition: fill 0.3s ease;
                    }

                    &:hover svg path {
                      fill: $accentYellow;
                    }
                  }
                }
              }
            }

            & .item__deactivated {
              & p,
              h1,
              h2,
              h3,
              h4,
              h5,
              h6 {
                color: $grayFour;
              }

              & .description__text {
                color: $grayFour;
              }

              & .item__title_wrapper {
                display: flex;
                align-items: center;
                border-bottom: none;
                margin-bottom: 0;
                padding-bottom: 0;

                & .item__title_message {
                  display: flex;
                }

                & .item__title,
                & .description__title {
                  cursor: default;
                  color: $grayFour;

                  & .title__deactivated {
                    font-weight: 300;
                  }

                  &:hover {
                    color: $grayFour;
                  }
                }

                & .item__icon {
                  display: flex;
                  align-items: center;
                  margin-left: 0.3rem;
                  cursor: pointer;

                  & svg path {
                    fill: $accentDark;
                    transition: fill 0.3s ease;
                  }

                  &:hover svg path {
                    fill: $accentYellow;
                  }
                }

                @media (max-width: 768px) {
                  .item__title {
                    font-size: 1.3rem;
                  }
                }
              }

              & .item__description {
                & .item__icon {
                  width: 1rem;
                }
              }
            }

            & .item__title_empty {
              font-weight: 400;
              font-size: 1.5rem;
              color: $accentDark;
              margin-bottom: 1rem;
              padding-bottom: 1rem;
              border-bottom: 1px solid $grayFour;
            }
          }
        }
      }

      .total__wrapper {
        .total {
          height: 100%;
          width: 16.875rem;
          max-width: 100%;
          position: sticky;
          top: 1.5rem;

          &__inner {
            padding: 1.25rem;
            border: 1px solid $grayFive;
            border-radius: 1rem;
            background-color: $white;
            margin-bottom: 1.25rem;

            & .total__item {
              display: flex;
              width: 100%;
              justify-content: space-between;
              margin-bottom: 0.9375rem;

              & p {
                color: $accentDark;
                font-weight: 500;
                font-size: 0.875rem;
              }
            }

            & .total__item__sum {
              display: flex;
              justify-content: space-between;

              & h3,
              & p {
                font-size: 1rem;
                font-weight: 700;
              }
            }

            & .total__item__sum_weight {
              & h3,
              & p {
                font-weight: 400;
              }
            }

            & .total__item__sum_success {
              flex-wrap: wrap;
              margin-top: 0.3rem;
              gap: 0.3rem;
            }

            & .delimiter {
              width: 100%;
              height: 1px;
              background-color: $grayFour;
              margin-bottom: 0.9375rem;
            }
          }

          &__buttons {
            display: flex;
            flex-direction: column;
            align-items: center;

            & .payment__button_repeat {
              width: 100%;

              & p {
                white-space: normal;
              }
            }

            & .total__icon {
              display: flex;
              align-items: center;
            }

            & button:not(:last-child) {
              margin-bottom: 0.75rem;
            }

            & button {
              text-transform: uppercase;
              align-items: center;
              display: flex;
              gap: 0.6875rem;
              @include mixinPrimaryButton(
                              0.875rem 3.125rem,
                              $accentYellow,
                              $accentDark,
                              none,
                              0.5rem,
                              max-content,
                              2.75rem
              );

              & p {
                font-size: 1rem;
                font-weight: 600;
                width: 100%;
                color: $textTitle;
              }

              & svg path {
                stroke: $accentDark;
              }

              &:disabled {
                background-color: $grayFive;
                color: $grayThree;
              }
            }

            & button:last-child {
              background-color: transparent;
              border: 1px solid $grayFour;
              width: 100%;
            }
          }

          & .total__warning {
            margin-bottom: 0.75rem;
            margin-top: -0.6rem;
            display: flex;
            align-items: center;
            height: 100%;

            & p {
              white-space: normal;
            }

            & .icon {
              // display: flex;
              // align-items: center;
              margin-right: 0.3rem;
              height: 1.4375rem;
              cursor: pointer;

              & svg path {
                fill: $accentDark;
                transition: fill 0.3s ease;
              }

              &:hover svg path {
                fill: $accentYellow;
              }
            }
          }
        }

        @media (max-width: 768px) {
          display: none;
        }
      }

      @media (max-width: 768px) {
        & .detail {
          margin-right: 0;
        }
      }
    }

    .total__wrapper_mobile {
      display: none;
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 5;
      margin: 0;

      .total {
        border: 1px solid $grayFive;
        border-radius: 1rem;
        background-color: $white;
        padding: 1rem;
        width: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &__inner {
          margin-bottom: 1rem;
          padding: 0;
          border: none;
          border-radius: 0;
          background-color: transparent;

          & .total__info_icon {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 0.3rem;

            & .total__info_button {
              display: flex;
              @include mixinPrimaryButton(0.375rem 0.625rem, $graySix, transparent, none, 1.375rem);
            }

            & svg {
              transition: transform 0.3s ease;
            }

            & .arrow_rotate {
              & svg {
                transform: rotate(180deg);
              }
            }
          }

          & .total__main_info {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            gap: 0.9375rem;

            & .total__item {
              display: flex;
              width: 100%;
              justify-content: space-between;

              & p {
                color: $accentDark;
                font-weight: 500;
                font-size: 0.875rem;
              }
            }

            & .delimiter {
              width: 100%;
              height: 1px;
              background-color: $grayFour;
              margin-bottom: 0.9375rem;
            }
          }

          & .total__item__sum {
            display: flex;
            justify-content: space-between;

            & h3,
            & p {
              font-size: 1rem;
              font-weight: 700;
            }
          }

          & .total__item__sum_weight {
            & h3,
            & p {
              font-weight: 400;
            }
          }

          & .total__item__sum_success {
            flex-wrap: wrap;
            margin-top: 0.3rem;
            gap: 0.3rem;
          }
        }

        &__buttons {
          display: flex;
          flex-direction: column;
          align-items: center;

          & .payment__button_repeat {
            width: 100%;

            & p {
              white-space: normal;
            }
          }

          & .total__icon {
            display: flex;
            align-items: center;
          }

          & button:not(:last-child) {
            margin-bottom: 0.75rem;
          }

          & button {
            text-transform: uppercase;
            align-items: center;
            display: flex;
            justify-content: center;
            gap: 0.6875rem;
            width: 100%;
            @include mixinPrimaryButton(0, $accentYellow, $accentDark, none, 0.5rem, 100%, 2.75rem);

            & p {
              font-size: 1rem;
              font-weight: 600;
              color: $textTitle;
            }

            & svg path {
              stroke: $accentDark;
            }

            &:disabled {
              background-color: $grayFive;
              color: $grayThree;
            }
          }

          & button:last-child {
            background-color: transparent;
            border: 1px solid $grayFour;
            width: 100%;
          }
        }

        & .total__warning {
          margin-bottom: 0.75rem;
          margin-top: -0.6rem;
          display: flex;
          align-items: center;
          height: 100%;

          & p {
            white-space: normal;
          }

          & .icon {
            // display: flex;
            // align-items: center;
            margin-right: 0.3rem;
            height: 1.4375rem;
            cursor: pointer;

            & svg path {
              fill: $accentDark;
              transition: fill 0.3s ease;
            }

            &:hover svg path {
              fill: $accentYellow;
            }
          }
        }
      }

      @media (max-width: 768px) {
        display: flex;
      }
    }

    @media (max-width: 768px) {
      padding: 0;
    }
  }
}
