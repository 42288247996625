@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.wrapper__container {
  background-color: $graySix;
  border-radius: 12px;
  @include mixinAuthContainer(25.8125rem, 1.75rem 1.5rem);
  max-width: 100%;

  & .save__wrapper {
    margin-bottom: 1rem;
  }

  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;

    &__title {
      display: flex;
      align-items: center;

      & .header__icon {
        display: flex;
        margin-right: 0.7813rem;

        & svg {
          width: 1.6875rem;
          height: 1.3125rem;
        }
      }

      & .title {
        font-weight: 600;
        font-size: 1.5rem;
        color: $accentDark;
      }
    }

    & .close {
      display: flex;

      & svg {
        cursor: pointer;
      }
    }
  }

  & .description {
    margin-bottom: 1rem;

    &__text {
      font-size: 0.875rem;
      color: $accentDark;
      width: 20rem;
      max-width: 100%;
    }

    & .description__info_wrapper {
      margin-top: 1rem;
      display: flex;
      width: 20rem;
      max-width: 100%;

      & .description__info_icon {
        display: flex;
        margin-right: 0.5rem;
        align-items: center;
      }
    }
  }

  & .group {
    &__choose {
      background-color: $white;
      border-radius: 0.75rem;
      margin-bottom: 1rem;
      padding: 1.9063rem 0;
      width: 23rem;
      max-width: 100%;

      & .group__menu {
        display: flex;
        flex-direction: column;
        background: $white;
        max-height: 14.5rem;
        overflow-y: scroll;
        height: 0;
        gap: 0.5rem;
        margin-right: 0.5rem;
        padding: 0 0.4rem 0 1.3125rem;

        & input {
          display: none;
        }

        &,
        & li {
          list-style: none;
        }

        & .choose__option {
          display: flex;
          align-items: center;
          cursor: pointer;

          & .radio {
            z-index: 1;
            cursor: pointer;
            width: 1.5rem;
            height: 1.5rem;
            min-width: 1.5rem;
            min-height: 1.5rem;
            position: relative;
            border-radius: 50%;
            background-color: $backgroundGrayAlmostWhite;
            border: 1px solid $borderPrimary;

            &:not(:last-child) {
              margin-right: 0.75rem;
            }

            &::after {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              display: block;
              background-color: transparent;
              width: 0.75rem;
              height: 0.75rem;
              border-radius: 50%;
            }
          }

          & .radio_active {
            border: none;
            background-color: $buttonPrimary;

            &::after {
              background-color: $white;
            }
          }

          & .choose__description {
            display: flex;
            align-items: center;
            background-color: $graySix;
            padding: 0.8125rem 0.6875rem;
            border-radius: 0.5rem;
            flex: 1;

            & .choose__icon {
              display: flex;
              margin-right: 0.6875rem;
            }

            & .choose__text {
              color: $textTitle;
              font-weight: 500;
              font-size: 1rem;
            }
          }
        }

        & .choose__option_hide {
          cursor: default;

          & .radio {
            cursor: default;
            background-color: rgba($grayFive, 0.3);
            border-color: rgba($grayFive, 0.3);
          }

          & .choose__description {
            background-color: rgba($grayFive, 0.3);
            pointer-events: none;

            & .choose__text {
              color: $grayThree;
            }
          }

          & svg path {
            fill: $grayFour;
          }

        }
      }

      & .delimiter {
        height: 1px;
        background-color: $graySix;
        height: 0px;
      }
    }

    & .buttons {
      display: flex;
      gap: 0.5rem;

      & button {
        display: flex;
        align-items: center;
        justify-content: center;
        @include mixinPrimaryButton(
                        0.7813rem 1.5rem,
                        $buttonPrimary,
                        $accentDark,
                        none,
                        8px,
                        11.1875rem,
                        2.75rem
        );

        & p {
          text-transform: uppercase;
          font-size: 1rem;
          font-weight: 600;
          width: max-content;
        }
      }

      & button:last-child {
        background-color: $grayFive;
      }

      @media (max-width: 768px) {
        width: 100%;

        & button {
          padding: 0 0.3rem;
          width: 100%;
        }
      }
    }
  }
}
