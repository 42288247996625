@import 'styles/vars.scss';

.label {
  &__title {
    color: $textPrimary;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
}

.wrapper {
  position: relative;
  width: 100%;
  display: flex;

  & .input__helper {
    position: absolute;
    top: 50%;
    color: $textPrimarySecondary;
    left: 6.6875rem;
    font-size: 18px;
    transform: translate(-50%, -50%);
    width: 4rem;
  }

  & input:first-child {
    font-size: 1rem;
    background-color: $backgroundGrayLight;
    border: 1px solid $borderPrimary;
    border-radius: 8px;
    width: 100%;
    outline: none;
    padding-left: 6.5rem;
    color: $textPrimarySecondary;
    height: 3.625rem;
    transition: box-shadow ease 0.25s, border-color ease 0.25s;

    @media (max-width: 480px) {
      font-size: 0.8rem;
    }
  }

  &__input__phone {
    width: 100%;
  }
}
