@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.wrapper {
  padding: 3.3125rem 1rem 2.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $graySix;
  width: 100%;

  & > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__container {
    @include mixinAuthContainer(59.75rem);
    max-width: 100%;

    & .wrapper__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;

      & .title {
        color: $buttonPrimaryText;
        font-size: 1.875rem;
      }

      & .wrapper__title_main {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        //flex-wrap: wrap;
        //gap: 0.5rem;
        margin-bottom: 1rem;
        flex-wrap: wrap;
        gap: 0.5rem;
      }

      & .services__sort_mobile {
        display: none;
        width: 100%;
        margin-bottom: 1rem;

        & .operators__sort {
          width: 100%;
        }

        & .switch__mobile {

        }

        & input {
          font-size: 1rem;
          height: 0;
          width: 0;

          @media (max-width: 480px) {
            font-size: 0.8rem;
          }
        }

        @media (max-width: 768px) {
          display: flex;
        }
      }

      & .list__main__buttons {
        display: flex;
        align-items: center;

        & .filters {
          display: flex;
          justify-content: flex-end;
          margin-right: 0.5rem;
          align-items: center;

          & .services__filters {
            width: 10rem;
            max-width: 100%;
          }

          & .filter__icon {
            display: flex;

            & svg {
              width: 1.6rem;
              height: 1.6rem;

              cursor: pointer;

              & path {
                stroke-width: 0.6rem;
                stroke: #000000;
              }
            }
          }

          & .services__filters {

            & input {
              width: 0;
              height: 0;
            }

            @media (max-width: 768px) {
              display: none;
            }
          }

          & .services__filter_mobile {
            align-items: center;
            justify-content: space-between;
            display: none;
            width: 100%;

            & .filter__title {
              font-size: 1.5rem;
              font-weight: 400;
            }

            & input {
              width: 0;
              height: 0;
            }

            @media (max-width: 768px) {
              display: flex;
            }
          }
        }

        & .create {
          margin-right: 0.5rem;

          &__button {
            display: flex;
            align-items: center;
            justify-content: center;
            @include mixinPrimaryButton(
                            0 0.6rem,
                            $accentYellow,
                            $textTitle,
                            none,
                            8px,
                            fit-content,
                            3rem,
                            600
            );
            transition: background-color 0.3s ease;

            & p {
              transition: color 0.3s ease;
              font-size: 1rem;
              text-transform: uppercase;
              color: $textTitle;
              font-weight: 600;
            }
          }

          @media (max-width: 768px) {
            margin-right: 0;
          }
        }
      }

      & .payment {
        &__button {
          @include mixinPrimaryButton(0 0.6rem, $grayFive, none, none, 8px, fit-content, 3rem, 600);
          transition: background-color 0.3s ease;

          & p {
            transition: color 0.3s ease;
            font-size: 1rem;
            text-transform: uppercase;
            color: $grayThree;
          }

          &_active {
            background-color: $accentYellow;
            color: $accentYellow;

            & p {
              color: $textTitle;
            }
          }
        }

        @media (max-width: 768px) {
          & button {
            width: 100%;
          }
          display: none;
        }
      }

      @media (max-width: 768px) {
      }

      @media (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    & .paginate__wrapper {
      @media (max-width: 768px) {
        display: none;
      }
    }

    & .employee__mobile__loader {
      width: 100%;
      margin: 1.25rem 0;
      display: none;
      justify-content: center;

      & .employee__load {
        @include mixinPrimaryButton(
                        1rem 1.3125rem,
                        $white,
                        $textPrimary,
                        none,
                        0.5rem,
                        '',
                        '',
                        500
        );
        display: flex;
        align-items: center;

        & p {
          font-weight: 400;
          font-size: 1rem;
          color: $textTitle;
          margin-right: 1rem;
        }

        & .load__icon {
          display: flex;
        }
      }

      @media (max-width: 768px) {
        display: flex;
      }
    }

    & .payment__mobile {
      display: none;
      width: 100%;

      &__button {
        @include mixinPrimaryButton(0, $grayFive, none, none, 8px, 100%, 3rem, 600);
        transition: background-color 0.3s ease;

        & p {
          transition: color 0.3s ease;
          font-size: 1rem;
          text-transform: uppercase;
          color: $grayThree;
        }

        &_active {
          background-color: $accentYellow;
          color: $accentYellow;

          & p {
            color: $textTitle;
          }
        }
      }

      @media (max-width: 768px) {
        display: flex;
        position: fixed;
        bottom: 0rem;
        left: 0;

        & button {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }

  & .search__mobile {
    display: none;
    position: relative;
    width: 100%;

    & > div:first-child {
      width: 100%;
    }

    & input {
      padding-left: 3.25rem;
      height: 3rem;
      width: 100%;
      background-color: $white;
      color: $grayThree;
    }

    & .search__icon {
      position: absolute;
      left: 1.25rem;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;
      height: 1.25rem;
    }

    @media (max-width: 768px) {
      display: flex;
      margin-bottom: 1rem;
    }
  }

  & .search {
    display: flex;
    position: relative;
    margin-bottom: 1rem;

    & > div:first-child {
      width: 100%;
    }

    & input {
      padding-left: 3.25rem;
      height: 3rem;
      width: 100%;
      background-color: $white;
      color: $grayThree;
    }

    & .search__icon {
      position: absolute;
      left: 1.25rem;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;
      height: 1.25rem;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  & .list {
    &__header {
      display: flex;
      align-items: center;
      height: 4rem;
      background-color: $white;
      border-radius: 8px;
      padding: 1.25rem 0.9375rem;
      margin-bottom: 1rem;

      & h4 {
        font-weight: 400;
        font-size: 1rem;
      }

      & .wrapper__list__tabs {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      & .list__tabs {
        display: flex;
        justify-content: space-between;
        width: 100%;

        & .list__tab {
          gap: 2.5rem;
        }
      }

      & .list__delimetr {
        width: 0.0625rem;
        height: 1.5rem;
        background-color: $grayFour;
        margin: 0 1rem;
      }

      @media (max-width: 1024px) {
        display: none;
      }
    }

    &__header_dropdown {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $white;

      border-radius: 0.5rem;
      cursor: pointer;
      margin-bottom: 1rem;
      display: none;

      & .header__left {
        display: flex;
        align-items: center;

        & .header__icon {
          margin-right: 0.75rem;
          display: flex;
        }

        & .header__title {
          & p {
            color: $grayTwo;
            font-size: 1rem;
            font-weight: 400;
          }
        }

        @media (max-width: 480px) {
          & .header__title {
            & p {
              font-size: 0.8rem;
            }
          }
        }
      }

      & .dropdown__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 1.25rem;

        & .header__icon_drop {
          display: flex;
          align-items: center;
          transition: transform 0.3s ease;

          & svg path {
            fill: $textTitle;
          }
        }
      }

      & .interactive__list {
        width: 100%;
        overflow: hidden;

        & .interactive__inner {
          padding: 0 1.25rem 1.25rem 1.25rem;
          margin-top: 1rem;

          & > label {
            & > label {
              margin-right: 0;
            }
          }

          & > div:not(:last-child) {
            margin-bottom: 1rem;
          }

          & .list__tabs {
            & .list__tab {
              &:not(:last-child) {
                margin-bottom: 1rem;
              }
            }

            @media (max-width: 1024px) {
              margin-top: 1rem;
            }
          }

          & .list__filters {
            & p {
              color: $textTitle;
            }
          }

          @media (max-width: 1024px) {
            margin-top: 0;
          }
        }

        @media (max-width: 480px) {
          & .list__tabs {
            & p {
              font-size: 0.8rem;
            }
          }
        }
      }

      @media (max-width: 1024px) {
        display: flex;
      }
    }

    & .content {
      &__header {
        display: flex;
        margin-bottom: 0.8125rem;
        padding-left: 0.9375rem;

        & .phone__wrapper {
          margin-left: -2rem;
        }

        & > div:nth-child(2) p {
          margin-right: 0.5rem;
        }

        &_item {
          display: flex;
          width: fit-content;
          align-items: center;
          flex: 1;

          & .item {
            cursor: pointer;
            align-items: center;
            display: flex;
            gap: 0.2rem;

            &:disabled {
              color: #000000;
              opacity: 1;
            }
          }

          & p {
            font-size: 1rem;
          }
        }

        @media (max-width: 768px) {
          display: none;
        }
      }

      & .content__main {
        width: 100%;

        & .content__items {
          display: flex;
          align-items: center;
          border-radius: 8px;
          background-color: $white;
          padding: 1.25rem 0 1.25rem 0.9375rem;
          position: relative;
          margin-bottom: 0.5rem;
          overflow: hidden;

          & .item__status {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 4px;
            height: 100%;
          }

          & .item__active {
            background-color: $statusSuccessful;
          }

          & .item__deactive {
            background-color: $statusFailed;
          }

          &__inner {
            flex: 2;
            display: flex;
          }

          & .content__item {
            flex: 1;

            & .redirect__button {
              transition: color 0.2s ease;
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              max-width: 8rem;
              overflow-y: hidden;
              margin-left: -3rem;

              &:disabled {
                color: #000000;
                opacity: 1;
              }

              &:disabled:hover {
                color: #000000;
              }

              &::-webkit-scrollbar {
                height: 0.5rem;
              }

              &:hover {
                color: $accentYellow;
              }
            }

            & .surname__wrapper,
            & .name__wrapper,
            & .phone__wrapper {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              max-width: 8rem;
              overflow-y: hidden;
              margin-left: -3rem;

              &::-webkit-scrollbar {
                height: 0.5rem;
              }
            }

            & > div {
              & label:last-child {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                max-width: 8rem;
                overflow-y: hidden;
              }
            }

            & label {
              &::-webkit-scrollbar {
                height: 0.5rem;
              }
            }

            & p {
              font-weight: 400;
              font-size: 1rem;
            }
          }

          & .content__items_icon {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;

            & svg {
              cursor: pointer;
              fill: $grayThree;
            }

            @media (max-width: 1024px) {
              right: 0.5rem;
            }
          }

          @media (max-width: 768px) {
            display: none;
          }
        }

        & .deactivated {
          //background-color: rgba($grayThree, 0.1);
          //cursor: default;
          //
          //& p {
          //  color: $grayFour;
          //}
          //
          //& svg path {
          //  //fill: $grayFour;
          //}

        }

        & .item__title_empty {
          margin-bottom: 1rem;
          font-weight: 400;
          font-size: 1.5rem;
          color: $accentDark;
        }

        & .main__loader {
          @media (max-width: 768px) {
            display: none;
          }
        }

        & .mobile__loader {
          display: none;
          @media (max-width: 768px) {
            display: flex;
          }
        }

        & .content__items_mobile {
          display: none;
          align-items: center;
          border-radius: 8px;
          background-color: $white;
          position: relative;
          margin-bottom: 0.5rem;
          cursor: pointer;
          flex-direction: column;
          width: 100%;
          overflow: hidden;

          & .item__status {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 4px;
            height: 100%;
          }

          & .item__active {
            background-color: $statusSuccessful;
          }

          & .item__deactive {
            background-color: $statusFailed;
          }

          & h5 {
            color: rgba($accentDark, 0.55);
            font-size: 0.75rem;
            font-weight: 400;
            margin-bottom: 0.3rem;
          }

          &__inner {
            flex: 2;
            display: flex;
          }

          & .icons {
            display: flex;
            gap: 1rem;
            align-items: center;

            & .icon_drop {
              display: flex;
              align-items: center;
              transition: transform 0.3s ease;

              & svg path {
                fill: $textTitle;
              }
            }
          }

          & .content__header {
            display: flex;
            width: 100%;
            padding: 1.25rem;
            margin: 0;

            & .content__item {
              flex: 1;
              overflow: hidden;

              & p {
                overflow-y: hidden;
                margin-right: 1rem;

                &::-webkit-scrollbar {
                  height: 0.3rem;
                }
              }

              & > div {
                & label:last-child {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  max-width: 8rem;
                  overflow-y: hidden;
                }
              }

              & label {
                &::-webkit-scrollbar {
                  height: 0.5rem;
                }
              }

              & p {
                font-weight: 400;
                font-size: 1rem;
              }

              @media (max-width: 768px) {
                & > div {
                  & label:last-child {
                    max-width: 100%;
                  }
                }
              }

              @media (max-width: 480px) {
                & p {
                  font-size: 0.8rem;
                }
              }

              @media (max-width: 360px) {
                & > div {
                  & label:last-child {
                    max-width: 8rem;
                  }
                }
              }
            }
          }

          & .content__items_icon {
            display: flex;
            align-items: center;
            height: fit-content;

            & svg {
              cursor: pointer;
              fill: $grayThree;
            }
          }

          @media (max-width: 768px) {
            display: flex;
          }
        }

        & .employee__list_mobile {
          overflow: hidden;
          width: 100%;

          & .employee__list_mobile_inner {
            padding: 0 1.25rem 1.25rem 1.25rem;

            & p {
              font-size: 1rem;

              overflow-y: hidden;

              &::-webkit-scrollbar {
                height: 0.3rem;
              }
            }

            & > div {
              & p {
                font-size: 1rem;
              }

              &:not(:last-child) {
                margin-bottom: 1.25rem;
              }
            }

            @media (max-width: 480px) {
              & p {
                font-size: 0.8rem;
              }
              & > div {
                & p {
                  font-size: 0.8rem;
                }
              }
            }
          }

          & .group__wrapper {

            & .redirect__button {
              transition: color 0.2s ease;
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              max-width: 8rem;
              overflow-y: hidden;

              &:disabled {
                color: #000000;
                opacity: 1;
              }

              &:disabled:hover {
                color: #000000;
              }

              &::-webkit-scrollbar {
                height: 0.5rem;
              }

              &:hover {
                color: $accentYellow;
              }
            }

            & p {
              overflow-y: hidden;

              &::-webkit-scrollbar {
                height: 0.3rem;
              }
            }
          }
        }
      }
    }

    @media (max-width: 768px) {
      margin-bottom: 3rem;
    }
  }
}

.list__tab_passive {
  pointer-events: none;

  & path,
  & p {
    transition: all 0.3s ease;
  }

  & > div:first-child {
    & svg path {
      fill: $grayThree;
    }
  }

  & > div:not(:first-child, .list__tab_import) {
    & svg path {
      stroke: $grayThree;
    }
  }

  & p {
    color: $grayThree;
  }
}

.list__tab_status {
  pointer-events: none;
}

.list__tab_active {
  & > div:hover {
    & p {
      color: $accentYellow;
    }

    &:first-child {
      & svg path {
        fill: $accentYellow;
      }
    }

    &:not(:first-child, .list__tab_import) {
      & svg path {
        stroke: $accentYellow;
      }
    }
  }

  & path,
  & p {
    transition: all 0.3s ease;
  }

  & > div:first-child {
    & svg path {
      fill: $textTitle;
    }
  }

  & > div:not(:first-child) {
    & svg path {
      stroke: $textTitle;
    }
  }

  & div p {
    color: $textTitle;
  }
}

.list__tab_active > .list__tab_import,
.list__tab_passive > .list__tab_import {
  pointer-events: all;

  &:hover {
    & p {
      color: $accentYellow;
    }

    & svg path {
      stroke: $accentYellow;
    }
  }

  & svg path {
    stroke: $textTitle;
    fill: none;
  }

  & p {
    color: $textTitle;
  }
}

.list__tab_status > .list__tab_deactivate {
  pointer-events: all;

  & svg path {
    stroke: $textTitle !important;
    fill: none !important;
  }

  &:hover {
    & p {
      color: $accentYellow;
    }

    & svg path {
      stroke: $accentYellow !important;
    }
  }

  & p {
    color: $textTitle;
  }
}

.list__tab_status > .list__tab_deactivate_fill {
  pointer-events: all;

  & svg path {
    stroke: none !important;
    fill: $textTitle !important;
  }

  &:hover {
    & p {
      color: $accentYellow;
    }

    & svg path {
      fill: $accentYellow !important;
    }
  }

  & p {
    color: $textTitle;
  }
}

