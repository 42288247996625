@import 'styles/vars.scss';

.wrapper__checkbox {
  display: flex;
  align-items: center;

  & .label_margin {
    margin-right: 0.75rem;
  }

  //& label:first-child {
  //  margin-right: 0.75rem;
  //}

  & .icon {
    display: flex;
    margin: 0 0.75rem;
  }
}

.label__input {
  width: 24px;
  height: 24px;
  border-radius: 8px;
  border: 1px solid $borderPrimary;
  background-color: $backgroundGrayAlmostWhite;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
  cursor: pointer;

  & svg {
    fill: $backgroundGrayLight;
  }

  & input {
    display: none;
  }
}

.label__title {
  width: max-content;

  & h4 {
    color: $textTitle;
    font-weight: 400;
    cursor: pointer;
  }
}
