@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.wrapper__container {
  background-color: $graySix;
  border-radius: 0.75rem;
  @include mixinAuthContainer(25.8125rem, 1.75rem 1.5rem);
  max-width: 100%;
  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
    &__title {
      display: flex;
      align-items: center;
      & .header__icon {
        display: flex;
        margin-right: 0.7813rem;

        & svg {
          width: 1.6875rem;
          height: 1.3125rem;
        }
      }

      & .title {
        font-weight: 600;
        font-size: 1.5rem;
        color: $accentDark;
      }
    }

    & .close {
      display: flex;

      & svg {
        cursor: pointer;
      }
    }
  }

  & .main {
    width: min-content;
    & .description {
      margin-bottom: 1rem;
      &__text {
        font-size: 0.875rem;
        color: $accentDark;
        width: 16rem;
        max-width: 100%;

        & .text_weight {
          font-weight: 700;
        }
      }
    }

    & .group {
      &__create {
        max-width: 100%;
        margin-bottom: 1rem;

        & input {
          height: 2.75rem;
        }

        &_label {
          color: $accentDark;
        }

        & .group__menu {
          display: flex;
          flex-direction: column;
          gap: 10px;
          background: $white;
          max-height: 14.5rem;
          overflow-y: scroll;
          height: 0;
          margin-right: 0.5rem;
          padding: 0 0.4rem 0 1.3125rem;
        }
      }
      & .buttons {
        display: flex;
        gap: 0.5rem;

        & button {
          display: flex;
          align-items: center;
          justify-content: center;
          @include mixinPrimaryButton(
            0.7813rem 1.5rem,
            $buttonPrimary,
            $accentDark,
            none,
            8px,
            fit-content,
            2.25rem
          );

          & p {
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: 600;
            width: max-content;
          }
        }

        & button:last-child {
          background-color: $grayFive;
        }

        @media (max-width: 480px) {
          width: 100%;

          & button {
            padding: 0 0.3rem;
            width: 100%;
          }
        }
      }
    }

    @media (max-width: 480px) {
      width: 100%;
    }
  }
}

.input__error {
  color: red;
  font-size: 0.95rem;
  font-weight: 400;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-top: 0.3rem;
  // position: absolute;
}

.border_alert {
  & input,
  & input:first-child {
    transition: border-color 0.3s ease;
    border-color: red;
  }
}
