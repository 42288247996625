@import 'styles/vars.scss';

.label {
  &__title {
    color: $textPrimary;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
}

.wrapper__input {
  position: relative;

  & input {
    background-color: $backgroundGrayLight;
    width: 100%;
    height: 3.625rem;
    border-radius: 0.5rem;
    padding-left: 1rem;
    font-size: 1.125rem;
    border: 1px solid $borderPrimary;

    @media (max-width: 480px) {
      font-size: 0.8rem;
    }
  }
}

.input__error {
  color: red;
  font-size: 0.95rem;
  font-weight: 400;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-top: 0.3rem;

  & p {
    overflow-y: hidden;

    &::-webkit-scrollbar {
      height: 0.5rem;
    }
  }
}
