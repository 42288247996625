@import 'styles/vars.scss';

.error {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: $backgroundPrimary;
  color: $white;
  padding: 0 1rem;

  &__title {
    font-size: 3rem;
    margin-bottom: 1rem;

    @media (max-width: 1024px) {
      font-size: 2.5rem;
    }

    @media (max-width: 768px) {
      font-size: 2rem;
    }

    @media (max-width: 480px) {
      font-size: 1.6rem;
    }
  }

  &__logo {
    display: flex;
    margin: 3rem 0;
    width: 100%;
    justify-content: center;

    @media (max-width: 1024px) {
      margin: 2rem 0;
    }

    @media (max-width: 768px) {
      margin: 1rem 0;
    }
  }

  &__message {
    width: 100%;
    margin-top: 1rem;
    text-align: center;

    & h1 {
      margin-bottom: 0.5rem;
      font-size: 2rem;
    }

    & p {
      font-size: 1.2rem;
      padding-bottom: 0.3rem;
      overflow-y: hidden;
      white-space: normal;

       &::-webkit-scrollbar {
        height: 0.5rem;
        margin-top: 1rem;
        }

        &::-webkit-scrollbar-thumb {
           background-color: $grayThree;
        }
    }

    @media (max-width: 1024px) {
      & p {
        font-size: 1.5rem;
      }
    }

    @media (max-width: 768px) {
      & p {
        font-size: 1rem;
      }
    }
  }

  &__description {
    width: 100%;
    & p {
      padding-bottom: 0.3rem;
      overflow-y: hidden;
      font-size: 2rem;
      white-space: normal;
      text-align: center;

      &::-webkit-scrollbar {
        height: 0.5rem;
        margin-top: 1rem;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $grayThree;
      }
    }

    @media (max-width: 1024px) {
      & p {
        font-size: 1.5rem;
      }
    }

    @media (max-width: 768px) {
      & p {
        font-size: 1rem;
      }
    }
  }

  &__back {
    display: flex;
    margin-top: 1rem;
    cursor: pointer;
    flex-direction: column;

    &_item {
      display: flex;

      &_inner {
        display: flex;

      }


      & p {
        font-size: 1rem;
        transition: color 0.3s ease;
      }

      &:hover p {
        color: $accentYellow;
      }

      &:hover .icon svg path {
        fill: $accentYellow;
      }

      & .icon {
        display: flex;
        margin-left: 0.3rem;

        & svg path {
          fill: $white;
          transition: fill 0.3s ease;
        }
      }
    }

    &_item:first-child {
      margin-bottom: 0.5rem;
    }
  }

  @media (max-width: 768px) {
    padding: 0 1rem;
  }

  @media (max-width: 480px) {
    padding: 0 0.8rem;
  }
}
