@import 'styles/vars.scss';

.next,
.prev {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.7rem;
  background-color: $white;
  border-radius: 8px;
  justify-content: center;
  border: 1px solid $borderPrimary;
  cursor: pointer;
}
