@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.wrapper {
  display: flex;
  width: 100%;
  min-height: 100%;
  background-color: $graySix;
  overflow: hidden;
  position: relative;

  & form {
    position: relative;
    z-index: 3;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & div {
      & label p {
        text-align: left;
      }

      & label {
        font-size: 1rem;
      }
    }

    @media (max-width: 768px) {
      width: 33.5rem;
      max-width: 100%;
    }
  }

  &__inner {
    @include mixinAuthFormWrapper;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1.5rem;

    &__title {
      text-align: center;
      width: 100%;
      h1 {
        @include mixinAuthTitle;
        margin-bottom: 1rem;
      }

      @media (max-width: 768px) {
        h1 {
          text-align: center;
        }
      }

      @media (max-width: 480px) {
        h1 {
          font-size: 1.8rem;
        }
      }
    }

    &__description {
      margin-bottom: 1.875rem;

      & p {
        color: $textPrimarySecondary;
        font-size: 1.125rem;
        text-align: center;
        line-height: 1.875rem;
      }

      @media (max-width: 768px) {
        & p {
          text-align: center;
        }
      }
    }

    &__container {
      @include mixinAuthContainer(32.25rem, 0);
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      & .signup {
        display: flex;
        font-size: 1.125rem;
        justify-content: center;

        & p {
          color: $textPrimarySecondary;
        }

        & p:first-child {
          margin-right: 0.3rem;
        }

        & p:last-child {
          font-weight: 600;
          cursor: pointer;
        }
      }

      @media (max-width: 480px) {
        padding: 4rem 0;
        text-align: center;

        & p {
          text-align: center;
        }
      }
    }

    &__buttons {
      display: flex;
      margin: 1.75rem 0 1.5rem 0;

      & button:first-child {
        margin-right: 1.625rem;
      }
    }

    & .wrapper__inner__button {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;

      &:not(:last-child) {
        margin-right: 1.5rem;
      }

      & input {
        z-index: 1;
        cursor: pointer;
        width: 25px;
        height: 25px;
        position: relative;
        border-radius: 50%;
        background-color: $backgroundGrayAlmostWhite;
        border: 1px solid $borderPrimary;

        &:not(:last-child) {
          margin-right: 0.75rem;
        }

        &:checked {
          border: none;
          background-color: $buttonPrimary;
        }

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          background-color: transparent;
          width: 12px;
          height: 12px;
          border-radius: 50%;
        }

        &:checked::after {
          background-color: $white;
        }
      }

      & p {
        font-weight: 500;
        font-size: 1rem;
      }
    }

    @media (max-width: 480px) {
      background-color: $accentYellow;
    }
  }

  & .circle {
    position: absolute;
    left: 0;
    background-color: $graySix;
    display: none;
    top: 0;
    height: 51rem;
    width: 51rem;
    max-height: 100%;
    border-radius: 0 0 47rem 0;

    @media (max-width: 480px) {
      display: flex;
    }

    @media (max-height: 768px) {
      height: 50rem;
      width: 44rem;
      border-radius: 0 0 48rem 0;
    }
  }

  &__logo {
    margin-bottom: 2rem;
    width: 100%;
    position: relative;
    z-index: 3;

    & svg {
      width: 100%;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      display: flex;
      align-items: center;
    }
  }
}

.form__inputs {
  width: 24rem;
  max-width: 100%;
  & .input {
    position: relative;
    margin-bottom: 1.8rem;
    &:first-child {
      margin-bottom: 1rem;
    }

    &__icon {
      display: flex;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }

    &__icon__mark {
      cursor: default;
      height: 0.8125rem;
      & svg {
        fill: $backgroundGrayExtraLight;
      }
    }

    & .email__fill {
      & svg {
        fill: $greenLight;
      }
    }

    & input {
    }

    &__label {
      font-weight: 500;
    }
  }

  & .input__code {
    & input {
      letter-spacing: 0.3rem;
    }
  }

  & .buttons {
    & .submit__button {
      margin-bottom: 1rem;
      @include mixinPrimaryButton(0, $buttonPrimary, $textTitle, none, 8px, 100%, 3.625rem, 600);

      &:disabled {
        background-color: $grayFive;
        color: $grayThree;
      }

      & p {
        font-size: 1.25rem;
      }

      @media (max-width: 480px) {
        p {
          font-size: 1rem;
        }
      }
    }

    & .back__button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;
      @include mixinPrimaryButton(
        0,
        $backgroundGrayLight,
        $textTitle,
        none,
        8px,
        100%,
        2.875rem,
        500
      );

      & svg {
        margin-right: 0.4rem;
        width: 0.875rem;
        height: 0.875rem;
        stroke: $textTitle;
        stroke-width: 10px;
      }

      & p {
        font-size: 1.25rem;
        font-weight: 600;
      }

      @media (max-width: 480px) {
        p {
          font-size: 1rem;
        }
      }
    }
  }

  & .form__helper {
    margin-bottom: 1.8125rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    & .checkbox {
    }

    & .link {
      display: flex;
      cursor: pointer;

      & p {
        color: $textPrimary;
      }
    }
  }
}

.input__countries {
  margin-bottom: 1rem;

  & .phone__input {
    position: relative;

    & .phone__select {
      position: absolute;
    }
    & input {
      padding-left: 50px;
    }
  }
}

.strength__bar {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 2.625rem;

  & > div {
    width: 100%;
  }
  & > div div {
    height: 3px !important;
    border-radius: 8px;
  }
}

.border_alert {
  & input {
    transition: border-color 0.3s ease;
    border-color: red;
  }
}
