@import 'styles/vars';

.wrapper {
  & .buttons {
    display: flex;
    margin: 1.75rem 0 1.5rem 0;

    & > div:first-child {
      margin-right: 1.5rem;
    }

    & p {
      width: max-content;
      color: $accentDark;
    }

    @media (max-width: 768px) {
      justify-content: center;
    }
  }

  & .button {
    display: flex;
    align-items: center;
  }

  & .button_active {
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    & p {
      margin-left: 0.4rem;
    }

    & svg path {
      fill: $textPrimary;
    }

    p {
      font-size: 1.25rem;
      font-weight: 500;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background: $buttonPrimary;
      border-radius: 1px;
    }
  }

  & .button_default {
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    & p {
      transition: color .3s ease;
      margin-left: 0.4rem;
      color: $grayFour;
      font-size: 1.25rem;
      font-weight: 500;
    }

    & svg path {
      transition: fill 0.3s ease;
      fill: $textPrimary;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      border-radius: 1px;
      transition: background 0.3s ease;
    }

    &:hover {
      &::before {
        background: $buttonPrimary;
      }

      p {
        color: $textTitle;
      }
    }
  }

  //@media (max-width: 480px) {
  //  background-color: $accentYellow;
  //}
}


