@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.wrapper__container {
  @include mixinAuthContainer(100%);
  max-width: 100%;

  & .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 1.25rem;

    &__title {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      & .title {
        font-weight: 600;
        font-size: 1.5rem;
        color: $accentDark;
        margin-bottom: 0.3rem;
      }
    }

    &__description {
      display: flex;
      align-items: center;

      & svg {
        width: 18px;
        height: 18px;
        min-height: 18px;
        min-width: 18px;
      }

      & .description {
        font-size: 0.75rem;
      }
    }

    & .close {
      display: flex;

      & svg {
        cursor: pointer;
      }
    }
  }

  & .main {
    width: 100%;

    & .description {
      margin-bottom: 1rem;

      &__wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        & .description__text {
          font-size: 0.875rem;
          color: $accentDark;
          max-width: 100%;
          display: flex;
          align-items: center;

          & p {
            width: fit-content;
            transition: color .3s ease;
            margin-right: 0.3rem;
            white-space: normal;
          }

          & .text_weight {
            font-weight: 700;
          }

          &:hover {
            color: rgba($accentDark, 0.5);
          }

          & svg {
            width: 0.8125rem;
          }

          & svg path {
            transition: fill .3s ease;
          }

          &:hover svg path {
            fill: rgba($accentDark, 0.5);
          }
        }

      }
    }

    & .employee {
      &__create {
        max-width: 100%;
        margin-bottom: 1rem;

        & input {
          height: 2.75rem;
        }

        &_label {
          color: $accentDark;
        }
      }

      & .buttons {
        display: flex;
        gap: 0.5rem;
        width: 100%;

        & button {
          display: flex;
          align-items: center;
          justify-content: center;
          @include mixinPrimaryButton(
                          0.7813rem 1.5rem,
                          $buttonPrimary,
                          $accentDark,
                          none,
                          8px,
                          7rem,
                          2.25rem
          );

          & p {
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: 600;
            width: max-content;
          }
        }

        @media (max-width: 3.6rem) {
          flex-direction: column;
        }

        @media (max-width: 4.8rem) {
          width: 100%;

          & button {
            padding: 0 0.3rem;
            width: 100%;
          }
        }
      }
    }

    @media (max-width: 4.8rem) {
      width: 100%;
    }
  }
}
