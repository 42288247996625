@media (max-width: 1600px) {
    html,
    body {
        font-size: 1rem;
    }
}

@media (max-width: 1550px) {
    html,
    body {
        font-size: .9688rem;
    }
}

@media (max-width: 1500px) {
    html,
    body {
        font-size: .9375rem;
    }
}

@media (max-width: 1450px) {
    html,
    body {
        font-size: .9063rem;
    }
}

//@media (max-width: 1400px) {
//    html,
//    body {
//        font-size: .875rem;
//    }
//}
//
//@media (max-width: 1350px) {
//    html,
//    body {
//        font-size: .8438rem;
//    }
//}
//
//@media (max-width: 1300px) {
//    html,
//    body {
//        font-size: .8125rem;
//    }
//}
//
//@media (max-width: 1250px) {
//    html,
//    body {
//        font-size: .7813rem;
//    }
//}
//
//@media (max-width: 1200px) {
//    html,
//    body {
//        font-size: .75rem;
//    }
//}
