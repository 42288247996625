@import 'styles/vars.scss';

/*.react-calendar__tile--active,*/
/*.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {*/
/*    background-color: #ffca00;*/
/*}*/

.react-calendar__tile--active,
.react-calendar__tile--active:focus,
.react-calendar__tile--active:enabled,
.react-calendar__tile--active:active {
    background-color: #ffca00 !important;
}

.react-calendar__tile--active:enabled:focus, .react-calendar__tile--active:enabled:hover,
.react-calendar__tile--hasActive:enabled:hover, .react-calendar__tile--hasActive:enabled:focus {
    background: #ffca00 !important;
}

.react-calendar__tile--active {
    background: #ffca00 !important;
}


.ant-picker-dropdown .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner,
.ant-picker-dropdown .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
    background-color: #ffffa9;
}


.react-calendar__tile--hasActive {
    background-color: #ffca00;
}

.ant-btn-primary,
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: #ffca00;
}

.ant-picker-now-btn,
.ant-picker-now-btn:hover,
.ant-picker-now-btn:focus,
.ant-picker-now-btn:enabled,
.ant-picker-now-btn:active {
    color: #ffca00;
}

.ant-picker-clear svg path {
    fill: #ffca00;
}

.ant-picker-suffix svg path {
    fill: #ffca00;
}

.ant-picker-focused.ant-picker {
    box-shadow: 0 0 0 2px rgba(255, 202, 0, 0.1);
}

.ant-picker-ranges button {
    background-color: #ffca00 !important;
}
