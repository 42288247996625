@import 'styles/vars.scss';

.wrapper {
  width: 100%;
  height: 100vh;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($backgroundPrimary, 0.62);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  & p {
    white-space: normal;
  }
}
