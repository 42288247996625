@import 'styles/vars';

.wrapper {
  width: 80px;
  min-width: 80px;
  height: 50px;
  background-color: rgba($grayFour, 0.4);
  display: flex;
  justify-content: flex-start;
  border-radius: 50px;
  padding: 5px;
  cursor: pointer;
  margin-right: 0.5rem;

  & .handle {
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.active {
  justify-content: flex-end;
}


