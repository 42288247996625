@import 'styles/vars.scss';

.wrapper {
  display: flex;
  margin: 1.7rem 0 2rem 0;
  position: relative;
  align-items: center;

  & .progress__title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.75rem;

    & p {
      color: $textPrimarySecondary;
    }
  }

  &__progress_bar {
    display: flex;
    width: 100%;

    & .progress_bar {
      width: 100%;
      border-radius: 10px;
      height: 4px;
      background-color: $backgroundGrayExtraLight;
      position: relative;

      &:not(:last-child) {
        margin-right: 0.25rem;
      }

      &_strength {
        position: absolute;
        border-radius: 10px;
        top: 0;
        left: 0;
        height: 100%;
      }
    }
  }

  &__progress_checker {
    width: 100%;
    position: absolute;
    height: 4px;
    border-radius: 10px;
    z-index: 1;
  }
}
