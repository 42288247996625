@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.employee__window {
  background-color: $white;
  position: fixed;
  right: 0;
  top: 0;
  min-height: 100%;
  height: -webkit-fill-available;
  z-index: 10;
  max-width: 100%;

  &__inner {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    & .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.25rem;
      width: max-content;
      &__title {
        display: flex;
        align-items: center;

        & .title {
          font-weight: 600;
          font-size: 1.5rem;
          color: $accentDark;
        }
      }

      & .close {
        display: flex;

        & svg {
          cursor: pointer;
        }
      }
    }

    & .employee__search {
      display: flex;
      position: relative;
      margin-bottom: 1.6875rem;

      & > div:first-child {
        width: 100%;
      }

      & input {
        padding-left: 3.25rem;
        height: 2.75rem;
        width: 100%;
        background-color: $white;
        color: $grayThree;
      }

      & .search__icon {
        position: absolute;
        left: 1.25rem;
        top: 50%;
        transform: translate(0, -50%);
        cursor: pointer;
        height: 1.25rem;
      }
    }

    & .employee__content {
      border: 1px solid $grayFive;
      border-radius: 0.5rem;
      margin-bottom: 1rem;
      height: 100%;
      padding: 0.375rem 0;
      overflow: scroll;
      overflow-x: hidden;

      & .content__items {
        padding: 0.375rem 1rem;
        position: relative;
        transition: background-color 0.3s ease;
        &:hover {
          background-color: $grayEight;
        }

        & .content__item {
          display: flex;
          justify-content: space-between;
          
          & .group__icons {
            display: flex;
            align-items: center;

            & .group__icon {
              display: flex;
              align-items: center;
            }

            & .item__icon {
              display: flex;
              margin-left: 0.6rem;
              position: relative;
              z-index: 3;
              cursor: pointer;

              & svg path {
                transition: fill 0.3s ease;
              }

              &:hover svg path {
                fill: $accentYellow;
              }
            }
          }

          & > div {
            & input {
              width: 100%;
              height: 100%;
              position: absolute;
              display: flex;
              left: 0;
            }
          }
        }

        & .content__item_all {
          & p {
            font-weight: 500;
          }
        }
      }

      & .empty_message {
        display: flex;
        width: 100%;
        justify-content: center;

        & p {
          font-size: 1rem;
        }
      }
    }
  }

  & .buttons {
    display: flex;
    gap: 0.5rem;

    & button {
      display: flex;
      align-items: center;
      justify-content: center;
      @include mixinPrimaryButton(
        0.7813rem 1.5rem,
        $buttonPrimary,
        $accentDark,
        none,
        8px,
        100%,
        2.75rem
      );

      & p {
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 600;
        width: max-content;
      }
    }

    & button:last-child {
      background-color: $grayFive;
    }
  }
}
