@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.wrapper {
  display: flex;
  width: 100%;
  min-height: 100%;
  background-color: $graySix;
  overflow: hidden;
  position: relative;

  & form {
    position: relative;
    z-index: 3;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & div {
      & label p {
        text-align: left;
      }

      & label {
        font-size: 1rem;
      }
    }

    @media (max-width: 768px) {
      width: 33.5rem;
      max-width: 100%;
    }
  }

  &__inner {
    @include mixinAuthFormWrapper;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem;

    &__title {
      h1 {
        @include mixinAuthTitle;
        margin-bottom: 1rem;
      }

      @media (max-width: 768px) {
        h1 {
          text-align: center;
        }
      }

      @media (max-width: 480px) {
        h1 {
          font-size: 1.8rem;
        }
      }
    }

    & .title__description {
      p {
        color: $textPrimary;
        margin-bottom: 2rem;
        font-size: 1.125rem;
      }

      @media (max-width: 768px) {
        & p {
          text-align: center;
        }
      }
    }

    &__container {
      @include mixinAuthContainer(100%, 1rem 0);
      max-width: 33.5rem;
      min-width: 24.6875rem;
      position: relative;

      & .wrapper__dark_logo {
        width: 100%;
        display: none;
        margin-bottom: 2rem;
        position: relative;
        z-index: 3;

        & svg {
          width: 100%;
        }

        @media (max-width: 768px) {
          flex-direction: column;
          display: flex;
          align-items: center;
        }
      }

      & .signup {
        display: flex;
        font-size: 1.125rem;
        justify-content: center;
        flex-wrap: wrap;
        gap: 0.3rem;

        & p {
          color: $textPrimarySecondary;
        }

        & p:last-child {
          font-weight: 600;
          cursor: pointer;
        }
      }

      @media (max-width: 480px) {
        min-width: 100%;
        padding: 4rem 0;
        text-align: center;

        & p {
          text-align: center;
        }
      }
    }

    &__buttons {
      display: flex;
      margin: 1.75rem 0 1.5rem 0;

      & > div:first-child {
        margin-right: 1.5rem;
      }

      & p {
        width: max-content;
        color: $accentDark;
      }

      @media (max-width: 768px) {
        justify-content: center;
      }
    }

    &__button {
      display: flex;
      align-items: center;
    }

    &__button_active {
      width: 100%;
      height: 3rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      & p {
        margin-left: 0.4rem;
      }

      & svg path {
        fill: $textPrimary;
      }

      p {
        font-size: 1.25rem;
        font-weight: 500;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background: $buttonPrimary;
        border-radius: 1px;
      }
    }

    &__button_default {
      width: 100%;
      height: 3rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      & p {
        margin-left: 0.4rem;
      }

      & svg path {
        transition: fill 0.3s ease;
        fill: $textPrimary;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        border-radius: 1px;
        transition: background 0.3s ease;
      }

      p {
        font-size: 1.25rem;
        font-weight: 500;
      }

      &:hover {
        &::before {
          background: $buttonPrimary;
        }

        p {
          color: $textTitle;
        }
      }
    }

    @media (max-width: 480px) {
      background-color: $accentYellow;
    }
  }

  & .circle {
    position: absolute;
    left: 0;
    background-color: $graySix;
    display: none;
    top: 0;
    height: 51rem;
    width: 51rem;
    border-radius: 0 0 47rem 0;

    @media (max-width: 480px) {
      display: flex;
    }
  }

  &__logo {
    @include mixinAuthLogoWrapper;
    padding: 0 1.5rem;

    &_inner {
      width: 100%;

      & svg {
        width: 100%;
      }
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
}

.form__inputs {
  & .input {
    margin-bottom: 1.5rem;
    position: relative;

    &__icon {
      display: flex;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }

    &__label {
      font-weight: 500;
    }
  }

  & .buttons {
    & .submit__button {
      margin-bottom: 1rem;
      @include mixinPrimaryButton(0, $buttonPrimary, $textTitle, none, 8px, 100%, 3.625rem, 600);

      &:disabled {
        background-color: $grayFive;
        color: $grayThree;
      }

      & p {
        font-size: 1.25rem;
      }

      @media (max-width: 480px) {
        p {
          font-size: 1rem;
        }
      }
    }

    & .google__button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2rem;
      @include mixinPrimaryButton(
        0,
        $backgroundGrayLight,
        $textTitle,
        none,
        8px,
        100%,
        2.875rem,
        500
      );

      & svg {
        margin-right: 0.75rem;
      }

      & p {
        font-size: 1rem;
      }
    }
  }

  & .form__helper {
    margin-bottom: 1.8125rem;
    display: flex;
    justify-content: space-between;
    font-size: 1.125rem;
    & .checkbox {
      & p {
        font-size: 1.125rem;
        cursor: pointer;
      }

      @media (max-width: 768px) {
        margin-bottom: 0.8125rem;
      }
    }

    & .link {
      display: flex;
      cursor: pointer;

      & p {
        color: $textPrimary;
      }
    }

    @media (max-width: 480px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.input__countries {
  margin-bottom: 1.5rem;

  & > div {
    height: 11.625rem;
    max-height: 100%;
  }

  & .phone__input {
    position: relative;

    & .phone__select {
      position: absolute;
    }
    & input {
      padding-left: 50px;
    }
  }
}

.input__error {
  color: red;
  font-size: 0.95rem;
  font-weight: 400;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-top: 0.3rem;
}

.border_alert {
  & input,
  & input:first-child {
    transition: border-color 0.3s ease;
    border-color: red;
  }
}
