$basicFont: 'Work Sans', sans-serif;


$white: #ffffff;
$greenLight: #16a249;
$yellowLight: #e7b008;
$alarm: red;


$textPrimary: rgba(26, 26, 26, 0.5);
$textPrimarySecondary: rgba(26, 26, 26, 0.7);
$textTitle: #1a1a1a;


$backgroundPrimary: #33434d;
$backgroundPrimaryDark: #25323a;
$backgroundSecondary: #f2f2f2;
$backgroundGrayLight: #fdfdfd;
$backgroundGrayExtraLight: rgba(26, 26, 26, 0.1);
$backgroundGrayAlmostWhite: rgba(26, 26, 26, 0.05);


$buttonPrimary: #ffca00;
$buttonPrimaryText: #1a1a1a;


$borderPrimary: rgba(26, 26, 26, 0.2);




$accentYellow: #ffca00;
$accentDark: #1a1a1a;


$grayOne: #333333;
$grayTwo: #4f4f4f;
$grayThree: #828282;
$grayFour: #bdbdbd;
$grayFive: #e0e0e0;
$graySix: #f2f2f2;
$graySeven: #e9e9e9;
$grayEight: #E8ECF1;


$statusSuccessful: #4EDB75;
$statusFailed: #FF6161;
