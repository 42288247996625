@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.backdrop {
  width: 100%;
  height: 100vh;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($backgroundPrimary, 0.62);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  .wrapper__container {
    background-color: $graySix;
    border-radius: 0.75rem;
    max-width: 100%;
    @include mixinAuthContainer(22.625rem, 1.75rem 1.5rem);
    & .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.25rem;
      &__title {
        display: flex;
        align-items: center;

        & .title {
          font-weight: 600;
          font-size: 1.5rem;
          color: $accentDark;
        }
      }

      & .close {
        display: flex;

        & svg {
          cursor: pointer;
        }
      }
    }

    & .main {
      width: 100%;

      & .operator {
        &__create_id {
          max-width: 100%;
          margin-bottom: 1rem;

          & input {
            height: 2.75rem;
            width: 100%;
            max-width: 100%;
          }

          & .operator__create_label {
            color: $accentDark;
          }
        }

        &__create_select {
          & input {
            height: auto;
          }
        }

        &__create_amount {
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
          }

          input[type='number'] {
            -moz-appearance: textfield; /* Firefox */
          }
        }

        & .buttons {
          display: flex;
          gap: 0.5rem;

          & button {
            display: flex;
            align-items: center;
            justify-content: center;
            @include mixinPrimaryButton(
              0.7813rem 1.5rem,
              $buttonPrimary,
              $accentDark,
              none,
              8px,
              100%,
              2.25rem
            );

            & p {
              text-transform: uppercase;
              font-size: 1rem;
              font-weight: 600;
              width: max-content;
            }
          }

          & button:last-child {
            background-color: $grayFive;
          }

          @media (max-width: 480px) {
            flex-direction: column;
            & button {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.paginate__count__mobile {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  padding: 0.3rem 1rem 0rem 1rem;

  & .count__divider {
    padding: 0 0.3rem;
  }
}
