@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.wrapper {
  padding: 1.875rem 1rem 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $graySix;
  width: 100%;

  &__container {
    position: relative;
    @include mixinAuthContainer(58.1875rem);
    max-width: 100%;

    & .operators__empty {
      display: flex;
      margin: 0.5rem 0;
      align-items: center;
      justify-content: center;
      width: 100%;

      & p {
        color: rgba(26, 26, 26, 0.5);
      }
    }

    & .wrapper__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.6875rem;

      & .title {
        color: $buttonPrimaryText;
        font-size: 2rem;
        font-weight: 500;
      }
    }

    & .operators__buttons {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin-top: 1.5625rem;

      & .operators__button:last-child {
        margin-left: 0.75rem;
        & button {
          background-color: $grayFive;
        }
      }

      & .operators__button {
        & button {
          @include mixinPrimaryButton(
            0.75rem 1.6875rem,
            $accentYellow,
            $accentDark,
            none,
            8px,
            9rem,
            2.5rem,
            0
          );
        }

        & p {
          font-weight: 600;
          font-size: 0.875rem;
          color: $accentDark;
          text-transform: uppercase;
        }
      }
    }
  }

  & form {
    position: relative;

    & p {
      color: $accentDark;
    }
  }

  & .back {
    display: flex;
    align-items: center;
    margin-bottom: 1.875rem;
    &_icon {
      background-color: $buttonPrimary;
      width: 2.25rem;
      height: 2.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
      margin-right: 0.75rem;
    }

    &_text {
      & p {
        font-size: 1.25rem;
        font-weight: 500;
        color: $accentDark;
      }
    }
  }

  .detail {
    background-color: $white;
    border-radius: 1.25rem;
    padding: 1.5rem;
    box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.1);
    position: relative;
    width: 100%;
    max-width: 100%;
    z-index: 2;

    &__title_header {
      border-bottom: 2px dashed $grayFour;
      padding-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & .detail__title {
        font-size: 1.5rem;
        color: $accentDark;
        font-weight: 600;
      }

      & .header__group__button {
        & button {
          display: flex;
          align-items: center;
          @include mixinPrimaryButton(
            0.6875rem 0.8125rem,
            $graySix,
            $textTitle,
            none,
            8px,
            100%,
            2.5rem,
            0
          );

          & p {
            font-weight: 600;
            font-size: 1rem;
          }

          & .group__icon {
            display: flex;
            margin-right: 0.6875rem;
          }
        }
      }
    }

    &__back {
      position: absolute;
      height: 40.1688rem;
      bottom: -1.3125rem;
      left: 50%;
      transform: translate(-50%, 0%);
      background-color: $white;
      width: 55.1875rem;
      z-index: 1;
      max-width: 100%;
      border-radius: 0.75rem;
      box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.1);
    }

    &__back__small {
      position: absolute;
      border-radius: 0.75rem;
      height: 40.1688rem;
      bottom: -2.1875rem;
      left: 50%;
      transform: translate(-50%, 0%);
      background-color: $white;
      width: 52.1875rem;
      max-width: 100%;

      &_hide {
        display: none;
      }
    }

    & .phone {
      margin-top: 1rem;
      border-bottom: 2px dashed $grayFour;
      padding-bottom: 2.1875rem;
      &__title {
        font-weight: 400;
        color: $accentDark;
      }

      &__choose {
        margin: 0.5rem 0 0.9375rem 0;

        .input__countries {
          & .countries__wrapper__input {
            max-width: 100%;
          }

          & .phone__items_remove {
            margin-left: 0.5rem;
          }

          & > div:not(:last-child) {
            margin-bottom: 1rem;
          }

          & > div {
            // width: fit-content;
            width: 100%;
          }

          & > div > div:last-child {
            & input {
              height: 2.875rem;
              width: 18.75rem;
              max-width: 100%;
            }
          }

          & .phone__input {
            position: relative;

            & .phone__select {
              position: absolute;
            }
            & input {
              padding-left: 50px;
              height: 2.5rem;
            }
          }

          & .input__label {
            font-weight: 500;
          }

          & .countries__wrapper__input {
            width: 100%;
            display: flex;
            align-items: center;
          }
        }

        @media (max-width: 480px) {
          & .input__countries {
            & .phone__items_remove {
              margin-left: 0.2rem;
            }
          }
        }
      }

      &__button {
        & button {
          @include mixinPrimaryButton(
            '.75rem 1.125rem',
            $graySix,
            $accentDark,
            none,
            0.5rem,
            8rem,
            2.5rem,
            600
          );

          & p {
            text-transform: uppercase;
            font-size: 0.875rem;
            width: max-content;
          }
        }
      }
    }

    & .operators {
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0.875rem 0;
      }

      &__wrapper__title {
        & .operators__title {
          //font-size: 1.25rem;
          //font-weight: 500;
          color: $accentDark;
          font-weight: 400;
        }
      }

      & .operators__sort {
        & input {
          font-size: 1rem;
          height: 0;
          width: 0;
        }

        @media (max-width: 480px) {
          font-size: 0.8rem;
        }
      }

      &__list {
        border: 1px solid $grayFive;
        border-radius: 0.625rem;
        margin-bottom: 1rem;
        overflow: hidden;
        &__header {
          border-top-left-radius: 0.625rem;
          border-top-right-radius: 0.625rem;
          padding: 0.5rem 1rem;
          display: flex;

          background-color: $graySix;
          & .header__item {
            flex: 1;
            color: $grayTwo;
            font-size: 1rem;

            & p {
              margin-right: 0.5rem;
            }

            & .item {
              display: flex;
              align-items: center;
              cursor: pointer;
              width: fit-content;
            }
          }

          @media (max-width: 768px) {
            display: none;
          }
        }

        & .operators__main {
          max-height: 13rem;
          height: 100%;
          overflow-y: scroll;
          & .operators__items {
            display: flex;
            position: relative;
            align-items: center;
            padding: 0.5rem 1rem;

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
            }

            input[type='number'] {
              -moz-appearance: textfield; /* Firefox */
            }

            & .operators__item {
              & .item__wrapper {
                width: fit-content;
                cursor: pointer;

                & input {
                  cursor: pointer;
                }

                & > div {
                  width: fit-content;
                }
              }
            }

            // & > div:first-child {
            //   & div input {
            //     height: 0;
            //     width: 0;
            //   }
            // }

            &_icon {
              position: absolute;
              right: 1.5625rem;
              display: flex;
              align-items: center;
              cursor: pointer;
              transform: translate(0%, -50%);
              top: 50%;

              & button {
                display: flex;
              }
            }

            & input,
            & div {
              font-size: 0.875rem;
            }

            &:not(:last-child) {
              border-bottom: 1px solid $grayFive;
            }

            & > div {
              flex: 1;

              & p {
                font-size: 1rem;
                color: $accentDark;
              }
            }

            &_remove {
              position: absolute;
              right: 1.5625rem;

              & .remove__button {
                display: flex;
                cursor: pointer;
              }
            }

            @media (max-width: 768px) {
              flex-direction: column;
              border: 1px solid $grayFive;
              border-radius: 1rem;
              margin-bottom: 1rem;

              &_icon {
                right: 1rem;
                top: 1.5rem;
              }

              & .operators__item {
                & .item__wrapper {
                  width: 100%;
                  & > div {
                    width: 100%;
                  }
                }

                & .item__inner__wrapper {
                  display: flex;

                  & .item__inner {
                    flex: 1;
                  }
                }

                & .item__inner {
                  &:not(:last-child) {
                    margin-bottom: 1rem;
                  }
                  & h5 {
                    display: flex;
                  }
                }
                & .operators__placeholder {
                  display: none;
                }
              }
            }

            @media (max-width: 1024px) {
              &_icon {
                right: 1rem;
              }
            }
          }

          & .operators__items__create {
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            gap: 0.5rem;

            @media (max-width: 768px) {
              padding-top: 2.5rem;
              & > .operators__item:nth-child(4) {
                margin-right: 0;
              }

              & .operators__placeholder {
                display: none;
              }
            }

            @media (max-width: 480px) {
              flex-direction: column;

              & .operators__item {
                width: 100%;
              }
            }
          }

          @media (max-width: 768px) {
            padding-right: 0.2rem;
          }
        }
        @media (max-width: 768px) {
          border: none;
          border-radius: 0;
        }
      }

      & .create {
        margin-right: 1.3125rem;
        &__button {
          @include mixinPrimaryButton(
            0.75rem 1.6875rem,
            $accentYellow,
            $accentDark,
            none,
            8px,
            fit-content,
            2.5rem,
            0
          );
          transition: color 0.3s ease;
        }
        & p {
          transition: color 0.3s ease;
          font-size: 0.875rem;
          text-transform: uppercase;
          color: $accentDark;
          font-weight: 600;
        }
      }

      @media (max-width: 480px) {
        &__header {
          flex-direction: column;
          align-items: flex-start;

          & .operators__sort {
            width: 100%;
          }

          & .operators__wrapper__title {
            margin-bottom: 1.5rem;
          }
        }
      }
    }

    &__pagination {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2.75rem;

      & > ul {
        width: auto;
      }
    }

    & .employee {
      display: flex;
      width: 100%;
      gap: 1rem;

      & .input {
        max-width: 100%;
        width: 18.75rem;
        & input {
          height: 2.75rem;
        }
      }

      @media (max-width: 768px) {
        flex-direction: column;

        & > div:last-child {
          grid-row-start: 2;
          grid-column-start: 1;
          grid-column-end: 1;
        }
      }

      @media (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);

        & > div:last-child {
          grid-column-end: 2;
        }
      }
    }

    & .group {
      margin-top: 1rem;
      &__choose {
        background-color: $white;
        border-radius: 0.75rem;
        width: 18.75rem;
        max-width: 100%;

        & .group__title {
          color: $accentDark;
          font-weight: 400;
          margin-bottom: 0.5rem;
        }

        & .group__menu {
          position: absolute;
          z-index: 3;
          width: 18.75rem;
          max-width: 100%;
          display: flex;
          flex-direction: column;
          background: $white;
          max-height: 9.5rem;
          overflow-y: scroll;
          height: 0;
          gap: 0.5rem;
          margin-right: 0.5rem;
          padding: 0 0.4rem 0 1.3125rem;

          & input {
            display: none;
          }

          &,
          & li {
            list-style: none;
          }

          & .choose__option {
            display: flex;
            align-items: center;
            cursor: pointer;

            & .radio {
              z-index: 1;
              cursor: pointer;
              width: 1.5rem;
              height: 1.5rem;
              position: relative;
              border-radius: 50%;
              background-color: $backgroundGrayAlmostWhite;
              border: 1px solid $borderPrimary;

              &:not(:last-child) {
                margin-right: 0.75rem;
              }

              &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: block;
                background-color: transparent;
                width: 0.75rem;
                height: 0.75rem;
                border-radius: 50%;
              }
            }

            & .radio_active {
              border: none;
              background-color: $buttonPrimary;

              &::after {
                background-color: $white;
              }
            }

            & .choose__description {
              display: flex;
              align-items: center;
              background-color: $graySix;
              padding: 0.8125rem 0.6875rem;
              border-radius: 0.5rem;
              flex: 1;
              & .choose__icon {
                display: flex;
                margin-right: 0.6875rem;
              }

              & .choose__text {
                color: $textTitle;
                font-weight: 500;
                font-size: 1rem;
              }
            }
          }

          & .choose__option_hide {
            cursor: default;

            & .radio {
              cursor: default;
              background-color: rgba($grayFive, 0.3);
              border-color: rgba($grayFive, 0.3);
            }

            & .choose__description {
              background-color: rgba($grayFive, 0.3);
              pointer-events: none;

              & .choose__text {
                color: $grayThree;
              }
            }

            & svg path {
              fill: $grayFour;
            }

          }
        }

        & .delimiter {
          height: 1px;
          background-color: $graySix;
          height: 0px;
        }
      }

      & .buttons {
        display: flex;
        gap: 0.5rem;

        & button {
          display: flex;
          align-items: center;
          justify-content: center;
          @include mixinPrimaryButton(
            0.7813rem 1.5rem,
            $buttonPrimary,
            $accentDark,
            none,
            8px,
            11.1875rem,
            2.75rem
          );

          & p {
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: 600;
            width: max-content;
          }
        }

        & button:last-child {
          background-color: $grayFive;
        }
      }
    }
  }
}

.input__error {
  color: red;
  font-size: 0.95rem;
  font-weight: 400;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-top: -0.7rem;
}

.border_alert {
  transition: border-color 0.3s ease;
  border-color: red;
  & input,
  & input:first-child {
    transition: border-color 0.3s ease;
    border-color: red;
  }
}

.paginate__count__mobile {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  padding: 0.3rem 1rem 0rem 1rem;

  & .count__divider {
    padding: 0 0.3rem;
  }
}
