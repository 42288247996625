@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.wrapper {
  padding: 3.3125rem 1rem 2.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $graySix;
  width: 100%;

  & > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__container {
    @include mixinAuthContainer(59.75rem);
    max-width: 100%;

    & .wrapper__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      margin-bottom: 1rem;

      & .wrapper__title_main {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
      }

      & .title {
        color: $buttonPrimaryText;
        font-size: 1.875rem;
      }

      @media (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;

        & h1 {
          margin-bottom: 1rem;
        }
      }

      & .services__sort_mobile {
        display: none;
        width: 100%;

        & .operators__sort {
          width: 100%;
        }

        & input {
          font-size: 1rem;
          height: 0;
          width: 0;

          @media (max-width: 480px) {
            font-size: 0.8rem;
          }
        }

        @media (max-width: 768px) {
          display: flex;
        }
      }

      @media (max-width: 480px) {
        & .wrapper__title_main {
          display: flex;
          justify-content: center;
          width: 100%;
          align-items: flex-start;
          flex-direction: column;
        }
      }
    }

    & .search__mobile {
      display: none;
      position: relative;
      margin-top: 1rem;
      width: 100%;

      & > div:first-child {
        width: 100%;
      }

      & input {
        padding-left: 3.25rem;
        height: 3rem;
        width: 100%;
        background-color: $white;
        color: $grayThree;
      }

      & .search__icon {
        position: absolute;
        left: 1.25rem;
        top: 50%;
        transform: translate(0, -50%);
        cursor: pointer;
        height: 1.25rem;
      }

      @media (max-width: 768px) {
        display: flex;
        margin-bottom: 1rem;
      }
    }

    & .search {
      display: flex;
      position: relative;
      margin-bottom: 1rem;

      & > div:first-child {
        width: 100%;
      }

      & input {
        padding-left: 3.25rem;
        height: 3rem;
        width: 100%;
        background-color: $white;
        color: $grayThree;
      }

      & .search__icon {
        position: absolute;
        left: 1.25rem;
        top: 50%;
        transform: translate(0, -50%);
        cursor: pointer;
        height: 1.25rem;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    & .groups__list {
      & .content {
        &__header {
          display: flex;
          margin-bottom: 0.8125rem;
          padding-left: 0.9375rem;

          &_item {
            flex: 1;
            font-size: 1rem;

            & .item {
              display: flex;
              cursor: pointer;
              width: fit-content;
              align-items: center;

              &:disabled {
                color: $textTitle;
                opacity: 1;
              }

              & p {
                margin-right: 0.5rem;
                font-size: 1rem;
              }
            }
          }

          @media (max-width: 768px) {
            display: none;
          }
        }

        & .content__main {
          width: 100%;
          & .content__items {
            display: flex;
            align-items: center;
            height: 3.5rem;
            border-radius: 8px;
            background-color: $white;
            padding: 1.25rem 0 1.25rem 0.9375rem;
            position: relative;
            margin-bottom: 0.5rem;

            & .content__item {
              flex: 1;
              display: flex;
              align-items: center;

              & .title__wrapper {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                max-width: 14rem;
                overflow-y: hidden;

                &::-webkit-scrollbar {
                  height: 0.5rem;
                }
              }

              & .description__wrapper {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                max-width: 21rem;
                overflow-y: hidden;
                margin-left: -3rem;

                &::-webkit-scrollbar {
                  height: 0.5rem;
                }
              }

              &_title {
                cursor: pointer;
                transition: color 0.2s ease;

                &:hover {
                  color: $yellowLight;
                }
              }

              & p {
                font-weight: 400;
                font-size: 1rem;
              }
            }

            & .content__items_icons {
              position: absolute;
              right: 1.25rem;
              top: 50%;
              transform: translate(-0, -50%);
              display: flex;
              align-items: center;
              gap: 1.4375rem;

              & .content__items_icon_arrow {
                padding: 0.75rem;
                border-radius: 50%;
                background-color: $accentYellow;

                &:disabled {
                  background-color: $grayFive;
                  color: $grayThree;
                  & svg {
                    cursor: default;
                    path {
                      fill: $grayThree;
                      stroke: $grayFive;
                    }
                  }
                }
              }

              & .content__items_icon {
                display: flex;
                & svg {
                  cursor: pointer;
                }

                &:first-child svg {
                  fill: $textTitle;
                }
              }

              & .content__items_stroke {
                & svg path {
                  stroke: $textTitle;
                }
              }

              @media (max-width: 1024px) {
                gap: 1rem;
              }
            }
          }

          & .main__loader {
            @media (max-width: 768px) {
              display: none;
            }
          }

          & .mobile__loader {
            display: none;
            @media (max-width: 768px) {
              display: flex;
            }
          }

          & .content__items_mobile {
            display: none;
            align-items: center;
            border-radius: 8px;
            background-color: $white;
            position: relative;
            margin-bottom: 0.5rem;
            cursor: pointer;
            flex-direction: column;
            width: 100%;

            & h5 {
              color: rgba($accentDark, 0.55);
              font-size: 0.75rem;
              font-weight: 400;
              margin-bottom: 0.3rem;
            }

            &__inner {
              flex: 2;
              display: flex;
            }

            & .icons {
              display: flex;
              gap: 1rem;
              height: fit-content;
              margin-left: 0.5rem;

              & .icon_drop {
                display: flex;
                align-items: center;
                transition: transform 0.3s ease;

                & svg path {
                  fill: $textTitle;
                }
              }
            }

            & .content__header {
              display: flex;
              width: 100%;
              align-items: center;
              padding: 1.25rem;
              margin: 0;

              & .content__item {
                flex: 1;
                overflow-y: hidden;
                margin-right: 1rem;

                &::-webkit-scrollbar {
                  height: 0.3rem;
                }

                &_info {
                  display: flex;
                  align-items: center;
                  overflow-y: hidden;

                  &::-webkit-scrollbar {
                    height: 0.3rem;
                  }

                  & p {
                    font-size: 0.8rem;
                  }

                  & > p:not(:last-child) {
                    margin-right: 0.3rem;
                  }

                  & .info__status {
                    min-width: 0.875rem;
                    min-height: 0.875rem;
                    border-radius: 50%;
                    margin-right: 0.3rem;

                    &_hide {
                      display: none;
                    }
                  }

                  & .content__item_info__inner {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                  }
                }

                & p {
                  font-weight: 400;
                  font-size: 1rem;
                }

                @media (max-width: 480px) {
                  & p {
                    font-size: 0.8rem;
                  }
                }
              }

              @media (max-width: 480px) {
                padding: 1rem 0.8rem;
              }
            }

            & .content__items_icon_arrow {
              padding: 0.75rem;
              border-radius: 50%;
              background-color: $accentYellow;

              &:disabled {
                background-color: $grayFive;
                color: $grayThree;
                & svg {
                  cursor: default;
                  path {
                    fill: $grayThree;
                    stroke: $grayFive;
                  }
                }
              }
            }

            & .content__items_icon {
              display: flex;
              align-items: center;

              & svg {
                cursor: pointer;
              }
            }

            @media (max-width: 768px) {
              display: flex;
            }
          }

          & .employee__list_mobile {
            overflow: hidden;
            width: 100%;

            & .employee__list_mobile_inner {
              padding: 0 1.2rem 1.2rem 1.2rem;

              & p {
                font-size: 1rem;
                font-weight: 400;
              }

              & .group__list {
                margin-bottom: 0.8rem;
              }

              & .description__wrapper {
                & p {
                  overflow-y: hidden;

                  &::-webkit-scrollbar {
                    height: 0.3rem;
                  }
                }

                &::-webkit-scrollbar {
                  height: 0.3rem;
                }

                &:not(:last-child) {
                  margin-bottom: 0.8rem;
                }

                & .info__status {
                  width: 0.875rem;
                  height: 0.875rem;
                  min-width: 0.875rem;
                  min-height: 0.875rem;
                  border-radius: 50%;
                  margin-left: 0.3rem;
                }

                & .status__successful {
                  background-color: $statusSuccessful;
                }

                & .status__in_process {
                  background-color: $accentYellow;
                }

                & .status__error {
                  background-color: $statusFailed;
                }

                &_status {
                  display: flex;
                }

                & .status__text__successful {
                  color: $statusSuccessful;
                }

                & .status__text__in_process {
                  color: $accentYellow;
                }

                & .status__text__error {
                  color: $statusFailed;
                }
              }

              & .group__list {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
              }

              @media (max-width: 480px) {
                & p {
                  font-size: 0.8rem;
                }
                padding: 0 0.8rem 0.8rem 0.8rem;
              }
            }
          }

          & .item__title_empty {
            font-weight: 400;
            font-size: 1.5rem;
            color: $accentDark;
            margin-bottom: 1rem;
          }
        }
      }
    }

    & .employee__mobile__loader {
      width: 100%;
      margin: 1.25rem 0;
      display: none;
      justify-content: center;

      & .employee__load {
        @include mixinPrimaryButton(
          1rem 1.3125rem,
          $white,
          $textPrimary,
          none,
          0.5rem,
          '',
          '',
          500
        );
        display: flex;
        align-items: center;

        & p {
          font-weight: 400;
          font-size: 1rem;
          color: $textTitle;
          margin-right: 1rem;
        }

        & .load__icon {
          display: flex;
        }
      }

      @media (max-width: 768px) {
        display: flex;
      }
    }

    & .paginate__wrapper {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
}
