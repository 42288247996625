@import 'styles/vars';

.btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.6rem 1.5rem 0.6rem 0.5rem;
  transition: all .3s ease;
  max-height: 37px;
  height: 37px;

  & p {
    color: $textTitle;
    font-size: 14px;
    font-weight: 500;
    transition: color .3s ease;
  }

  & .icon {
    display: flex;
    margin-right: 0.5rem;

    & svg {
      transition: stroke .3s ease;
      stroke: $textTitle;
      fill: none;
    }
  }

  &:hover {
    background-color: rgba(37, 50, 58, 0.7);

    & p {
      color: $white;
    }

    & svg {
      stroke: $white;
      fill: none;
    }
  }
}
