@import 'styles/main.scss';

.pagination__container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.page__item {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  & a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: $textPrimary;
    transition: font-weight 0.3s ease;
  }
}

.page {
  &__current {
    background-color: $buttonPrimary;

    & a {
      font-weight: 600;
      color: $textTitle;
    }
  }

  &__break {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 5px;
  }
}
