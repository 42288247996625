.select {
  width: 18.75rem;
  max-width: 100%;
  position: relative;
  font-size: 1rem;

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
}

.children__container {
  position: absolute;
  top: 50%;
  left: 1.125rem;
  transform: translate(0%, -50%);

  height: 100%;
  display: flex;
  align-items: center;
}
