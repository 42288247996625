@import 'styles/vars.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.125rem;
  border-radius: 0.5rem;
  background-color: $graySix;
  position: relative;
  overflow: hidden;
  justify-content: flex-start;
  text-align: center;

  & .track__progress {
    width: 100%;
    left: 0;
    height: 100%;
  }

  &__inner {
    position: absolute;
    width: 100%;
    z-index: 2;
    & p {
      font-size: 1rem;
      color: $accentDark;
    }
  }
}
