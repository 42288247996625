@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.wrapper {
  padding: 1.875rem 1rem 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $graySix;
  width: 100%;

  &__container {
    position: relative;
    @include mixinAuthContainer(58.1875rem);
    max-width: 100%;

    & .operators__empty {
      display: flex;
      margin: 0.5rem 0;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: rgba(26, 26, 26, 0.5);
    }

    & form {
      position: relative;
    }

    & .back {
      display: flex;
      align-items: center;
      margin-bottom: 1.875rem;

      &_icon {
        background-color: $buttonPrimary;
        width: 2.25rem;
        height: 2.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        margin-right: 0.75rem;
      }

      &_text {
        & p {
          font-size: 1.25rem;
          font-weight: 500;
          color: $accentDark;
        }
      }
    }

    .paginate__buttons__mobile {
      display: none;
      width: 100%;
      margin-bottom: 1rem;
      justify-content: flex-end;

      & button:first-child {
        margin-right: 0.5rem;
      }

      @media (max-width: 768px) {
        display: flex;
      }
    }

    .detail {
      background-color: $white;
      border-radius: 1.25rem;
      padding: 1.5rem;
      box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.1);
      position: relative;
      width: 100%;
      max-width: 100%;
      z-index: 2;

      .group {
        margin-top: 1rem;

        & .header__group__button {
          margin-top: 0.5rem;
          width: 18.75rem;
          max-width: 100%;
          display: flex;
          align-items: center;
          gap: 0.6rem;

          & .header__group__button_btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include mixinPrimaryButton(
                            0.6875rem 0.8125rem,
                            $graySix,
                            $textTitle,
                            none,
                            8px,
                            100%,
                            2.5rem,
                            0
            );

            & .group__left_item,
            & .group__right_item {
              display: flex;
              align-items: center;
            }

            & .group__right_item svg path {
              transition: all .3s ease;
            }

            & p {
              font-weight: 600;
              font-size: 1rem;
            }

            & .group__icon {
              display: flex;
              margin-right: 0.6875rem;
            }

            & p {
              transition: color 0.2s ease;
            }

            &:hover p {
              color: $yellowLight;
            }

            &:hover .group__right_item svg path {
              fill: $yellowLight;
            }
          }

          & .header__group__button_inactive {

            & .group__right_item svg path {
              fill: rgba($textTitle, 0.5);
            }

            &:hover p {
              color: rgba($textTitle, 0.5);
            }

            &:hover .group__right_item svg path {
              fill: rgba($textTitle, 0.5);
            }
          }

          & button:disabled {
            opacity: 0.5;

            &:hover p {
              color: $accentDark;
            }
          }

          .edit__icon button {
            display: flex;
          }
        }


        & .buttons {
          display: flex;
          gap: 0.5rem;

          & button {
            display: flex;
            align-items: center;
            justify-content: center;
            @include mixinPrimaryButton(
                            0.7813rem 1.5rem,
                            $buttonPrimary,
                            $accentDark,
                            none,
                            8px,
                            11.1875rem,
                            2.75rem
            );

            & p {
              text-transform: uppercase;
              font-size: 1rem;
              font-weight: 600;
              width: max-content;
            }
          }

          & button:last-child {
            background-color: $grayFive;
          }
        }
      }

      & .employee__input {
        display: flex;

        & .employee__input__inner {
          display: flex;
          gap: 0.6rem;
          margin-right: 0.6rem;
        }

        & .input__item {
          display: flex;
          flex-direction: column;
        }

        & .operators__items_icon_edit {
          display: flex;
          align-items: flex-end;

          & button {
            display: flex;
            align-items: center;
            height: 2.0625rem;
          }
        }

        @media (max-width: 480px) {
          & .employee__input__inner {
            flex-direction: column;
          }

          & .operators__items_icon_edit {
            & button {
              height: 6.0625rem;
            }
          }
        }
      }

      & .detail__inner {
        display: flex;
        border-bottom: 2px dashed $grayFour;
        padding-bottom: 1rem;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 0.6rem;

        & .header__group__button {
          & button {
            display: flex;
            align-items: center;
            @include mixinPrimaryButton(
                            0.6875rem 0.8125rem,
                            $graySix,
                            $textTitle,
                            none,
                            8px,
                            100%,
                            2.5rem,
                            0
            );

            & p {
              font-weight: 600;
              font-size: 1rem;
            }

            & .group__icon {
              display: flex;
              margin-right: 0.6875rem;
            }

            & p {
              transition: color 0.2s ease;
            }

            &:hover p {
              color: $yellowLight;
            }
          }
        }
      }

      &__title_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        & .detail__title_wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          flex-wrap: wrap;
          gap: 0.6rem;

          & button:disabled {
            opacity: 0.5;
          }

          & .wrapper__title {
            display: flex;
            align-items: center;
            gap: 0.6rem;
            margin-right: 1rem;

            & .detail__title {
              font-size: 1.5rem;
              color: $accentDark;
              font-weight: 600;
            }

            @media (max-width: 360px) {
              margin-right: 0;
            }
          }

          & .operator_activated,
          & .operator_deactivated {
            display: flex;
            align-items: center;
            justify-content: center;
            @include mixinPrimaryButton(
                            0.5rem,
                            $buttonPrimary,
                            $accentDark,
                            none,
                            8px,
                            fit-content,
                            2.25rem
            );

            & p {
              text-transform: uppercase;
              font-size: 1rem;
              font-weight: 600;
              width: max-content;
            }
          }

          & .operator_activated {
            background-color: $accentYellow;
          }

          & .operator_deactivated {
            background-color: $grayFive;
          }

          & button {
            display: flex;
          }
        }
      }

      &__back {
        position: absolute;
        height: 20.1688rem;
        bottom: -1.3125rem;
        left: 50%;
        transform: translate(-50%, 0%);
        background-color: $white;
        width: 55.1875rem;
        z-index: 1;
        max-width: 100%;
        border-radius: 0.75rem;
        box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.1);

        @media (max-width: 1024px) {
          display: none;
        }
      }

      &__back__small {
        position: absolute;
        border-radius: 0.75rem;
        height: 20.1688rem;
        bottom: -2.1875rem;
        left: 50%;
        transform: translate(-50%, 0%);
        background-color: $white;
        width: 52.1875rem;
        max-width: 100%;

        &_hide {
          display: none;
        }

        @media (max-width: 1024px) {
          display: none;
        }
      }

      & .phone {
        margin-top: 0.9375rem;
        border-bottom: 2px dashed $grayFour;
        padding-bottom: 2.1875rem;

        &__title {
          font-weight: 400;
          color: $accentDark;
        }

        &__choose {
          margin: 0.5rem 0 0.9375rem 0;
          font-size: 1rem;

          &_inner {
            margin-right: 1rem;
            display: flex;
            align-items: center;
            gap: 0.6rem;

            & .edit_btn:disabled {
              opacity: 0.5;
            }

            & > div {
              max-width: 100%;
              display: flex;
            }
          }

          & .input__countries {
            margin-right: 1rem;
          }

          & .employee__input__inner {
            max-width: 100%;
          }

          & .edit__icons {
            display: flex;
            align-items: center;

            & .edit__icon {
              & .edit_btn {
                display: flex;
                height: 100%;
              }

              & .edit__mark_btn {
                margin-right: 0.6rem;

                & svg {
                  stroke: $accentDark;
                  stroke-width: 1px;
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }

          & input {
            // height: 0;
            // width: 0;

            @media (max-width: 480px) {
              font-size: 0.8rem;
            }
          }

          @media (max-width: 480px) {
            font-size: 0.8rem;
          }

          .input__countries {
            margin-top: 1rem;

            & .countries__wrapper__input {
              max-width: 100%;
            }

            & .phone__items_remove {
              margin-left: 0.5rem;
            }

            & > div:not(:last-child) {
              margin-bottom: 1rem;
            }

            & > div {
              // width: fit-content;
              width: 100%;
            }

            & > div > div:last-child {
              & input {
                height: 2.875rem;
                width: 18.75rem;
                max-width: 100%;
              }
            }

            & .phone__input {
              position: relative;

              & .phone__select {
                position: absolute;
              }

              & input {
                padding-left: 50px;
                height: 2.5rem;
              }
            }

            & .input__label {
              font-weight: 500;
            }
          }

          & .input__countries_edit {
            margin-top: 0;
            margin-right: 0;

            & .countries__wrapper__input {
              max-width: 100%;
            }
          }
        }

        &__button {
          & button {
            @include mixinPrimaryButton(
                            '.75rem 1.125rem',
                            $graySix,
                            $accentDark,
                            none,
                            0.5rem,
                            8rem,
                            2.5rem,
                            600
            );

            & p {
              text-transform: uppercase;
              font-size: 0.875rem;
              width: max-content;
            }
          }

          & button:disabled {
            opacity: 0.5;
            background-color: #e0e0e0;
          }
        }
      }

      & .operators {
        &__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0.875rem 0;
        }

        &__wrapper__title {
          & .operators__title {
            //font-size: 1.25rem;
            //font-weight: 500;
            //color: $accentDark;
            //margin-right: 1rem;
            font-weight: 400;
            color: $accentDark;
          }
        }

        & .operators__sort {
          display: none;
          font-size: 1rem;

          & input {
            font-size: 1rem;
            height: 0;
            width: 0;

            @media (max-width: 480px) {
              font-size: 0.8rem;
            }
          }

          @media (max-width: 480px) {
            font-size: 0.8rem;
          }

          @media (max-width: 768px) {
            display: flex;
          }
        }

        &__list {
          border: 1px solid $grayFive;
          border-radius: 0.625rem;
          margin-bottom: 1rem;

          &__header {
            border-top-left-radius: 0.625rem;
            border-top-right-radius: 0.625rem;
            padding: 0.5rem 1.375rem 0.5rem 1rem;
            display: flex;

            background-color: $graySix;

            & .header__item {
              flex: 1;
              color: $grayTwo;
              font-size: 1rem;

              & p {
                margin-right: 0.5rem;
              }

              & .item {
                display: flex;
                align-items: center;
                cursor: pointer;
                width: fit-content;
              }
            }

            @media (max-width: 768px) {
              display: none;
            }
          }

          & .operators__main {
            max-height: 13.4375rem;
            overflow: scroll;
            overflow-x: hidden;

            & .operators__items {
              display: flex;
              position: relative;
              padding: 0.5rem 1rem;
              align-items: center;

              input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
              }

              input[type='number'] {
                -moz-appearance: textfield; /* Firefox */
              }

              // & > div:first-child {
              //   & div input {
              //     height: 0;
              //     width: 0;
              //   }
              // }

              & input,
              & div {
                font-size: 0.875rem;
              }

              &:not(:last-child) {
                border-bottom: 1px solid $grayFive;
              }

              & > div {
                flex: 1;

                & button:disabled {
                  opacity: 0.5;
                }

                & p {
                  font-size: 1rem;
                  color: $accentDark;
                }
              }

              &_remove {
                position: absolute;
                right: 1.5625rem;

                & .remove__button {
                  display: flex;
                  cursor: pointer;
                }
              }

              & .operators__item {
                flex: 1;

                & p {
                  font-size: 1rem;
                  color: $accentDark;
                  overflow-y: hidden;
                  max-width: 11rem;

                  &::-webkit-scrollbar {
                    height: 0.3rem;
                  }
                }

                & .item__inner {
                  h5 {
                    display: none;
                    color: $grayFour;
                    font-size: 1rem;
                    font-weight: 400;
                  }

                  &:first-child p {
                    margin-right: 3.6rem;
                  }

                  & p {
                    overflow-y: hidden;

                    &::-webkit-scrollbar {
                      height: 0.3rem;
                    }
                  }
                }

                @media (max-width: 768px) {
                  & p {
                    max-width: 100%;
                  }
                }
              }

              &_icon {
                position: absolute;
                right: 0.9rem;
                display: flex;
                align-items: center;
                cursor: pointer;
                transform: translate(0%, -50%);
                top: 50%;

                & button {
                  display: flex;
                }
              }

              &_icon_edit {
                position: absolute;
                right: 3rem;
                display: flex;
                align-items: center;
                cursor: pointer;
                transform: translate(0%, -50%);
                top: 50%;

                & button {
                  display: flex;
                }
              }

              @media (max-width: 768px) {
                flex-direction: column;
                border: 1px solid $grayFive;
                border-radius: 1rem;
                margin-bottom: 1rem;
                align-items: normal;

                &_icon {
                  right: 1rem;
                  top: 1.5rem;
                }

                &_icon_edit {
                  top: 1.5rem;
                }

                & .operators__item {
                  & .item__inner__wrapper {
                    display: flex;

                    & .item__inner {
                      flex: 1;
                    }

                    @media (max-width: 360px) {
                      flex-direction: column;
                      margin-bottom: 1rem;
                    }
                  }

                  & .item__inner {
                    &:not(:last-child) {
                      margin-bottom: 1rem;
                    }

                    & h5 {
                      display: flex;
                    }
                  }
                }

                @media (max-width: 768px) {
                  & .operators__placeholder {
                    display: none;
                  }
                }
              }
            }

            & .operators__items_edit {
              padding: 0.5rem 0 0.5rem 0;
              border: none;

              & .operators__items_icon {
                right: 0;
              }

              & .operators__items_icon_edit {
                right: 2rem;
              }
            }

            & .operators__items__create {
              flex-direction: row;
              flex-wrap: wrap;
              align-items: center;
              gap: 0.5rem;

              @media (max-width: 768px) {
                padding-top: 2.5rem;
                & > .operators__item:nth-child(4) {
                  margin-right: 0;
                }

                & .operators__placeholder {
                  display: none;
                }
              }

              @media (max-width: 480px) {
                flex-direction: column;

                & .operators__item {
                  width: 100%;
                }
              }
            }

            @media (max-width: 768px) {
              padding-right: 0.2rem;
            }
          }

          @media (max-width: 768px) {
            border: none;
            border-radius: 0;
          }
        }

        @media (max-width: 480px) {
          &__header {
            flex-direction: column;
            align-items: flex-start;

            & .operators__sort {
              width: 100%;
            }

            & .operators__wrapper__title {
              margin-bottom: 1.5rem;
            }
          }
        }
      }

      & .operators__interactive {
        display: flex;
        justify-content: space-between;

        & .operators__button_add {
          & button {
            @include mixinPrimaryButton(
                            0.75rem 1.6875rem,
                            $accentYellow,
                            $accentDark,
                            none,
                            8px,
                            6.0625rem,
                            2.5rem,
                            0
            );
          }

          & button:disabled {
            opacity: 0.5;
            background-color: #e0e0e0;
          }

          & p {
            font-weight: 600;
            text-transform: uppercase;
          }
        }

        & .operators__buttons {
          display: flex;
          gap: 0.75rem;

          & .operators__button:first-child {
            & button {
              background-color: $accentYellow;
            }
          }

          & .operators__button {
            & button {
              @include mixinPrimaryButton(
                              0.75rem 1.6875rem,
                              $grayFive,
                              $accentDark,
                              none,
                              8px,
                              9rem,
                              2.5rem,
                              0
              );
            }

            & p {
              font-weight: 600;
              font-size: 0.875rem;
              color: $accentDark;
              text-transform: uppercase;
            }
          }
        }

        @media (max-width: 768px) {
          & .operators__buttons {
            & .operators__button {
              & button {
                width: 7rem;
              }
            }
          }
        }

        @media (max-width: 480px) {
          flex-direction: column;

          & .operators__buttons {
            margin-top: 0.75rem;
            flex-wrap: wrap;
            gap: 0.75rem;

            & .operators__button {
              width: 100%;

              & button {
                width: 100%;
              }
            }
          }
        }
      }

      &__pagination {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2.75rem;

        & > ul {
          width: auto;
        }
      }
    }
  }
}

.border_alert {
  transition: border-color 0.3s ease;
  border-color: red;

  & input,
  & input:first-child {
    transition: border-color 0.3s ease;
    border-color: red;
  }
}

.paginate__count__mobile {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  padding: 0.3rem 1rem 0rem 1rem;

  & .count__divider {
    padding: 0 0.3rem;
  }
}
