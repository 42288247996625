@import 'styles/vars';
@import 'styles/mixins';

.wrapper {
  //width: 44.44px;
  //min-width: 44.44px;
  //height: 20px;
  //background-color: rgba($grayFour, 0.4);
  //display: flex;
  //justify-content: flex-start;
  //align-items: center;
  //border-radius: 50px;
  //padding: 0 5px;
  //cursor: pointer;

  & .fields {
    display: flex;

    & .field:first-child {
      margin-right: 0.5rem;
      flex: 1;
    }

    & .field:last-child {
      width: 100%;
      flex: 3;
    }

  }

  & .btn {
    display: flex;
    align-items: center;
    position: relative;

    @include mixinPrimaryButton(
                    0.5rem 1rem,
                    #F8FAFC,
                    $accentDark,
                    1px solid $grayEight,
                    8px,
                    10.8rem,
                    2.25rem,
                    500
    );

    & .icon {
      display: flex;
      position: absolute;
      right: 1rem;
    }
  }
}
