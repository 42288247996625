@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.wrapper {
  padding: 1.875rem 1rem 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $graySix;
  width: 100%;

  & > div {
    & .employee__window {
      background-color: $white;
      position: fixed;
      right: 0;
      top: 0;
      min-height: 100%;
      height: -webkit-fill-available;
      z-index: 4;
      max-width: 100%;

      &__inner {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        & .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 1.25rem;
          width: max-content;
          &__title {
            display: flex;
            align-items: center;

            & .title {
              font-weight: 600;
              font-size: 1.5rem;
              color: $accentDark;
            }
          }

          & .close {
            display: flex;

            & svg {
              cursor: pointer;
            }
          }
        }

        & .employee__search {
          display: flex;
          position: relative;
          margin-bottom: 1.6875rem;

          & > div:first-child {
            width: 100%;
          }

          & input {
            padding-left: 3.25rem;
            height: 2.75rem;
            width: 100%;
            background-color: $white;
            color: $grayThree;
          }

          & .search__icon {
            position: absolute;
            left: 1.25rem;
            top: 50%;
            transform: translate(0, -50%);
            cursor: pointer;
            height: 1.25rem;
          }
        }

        & .employee__content {
          border: 1px solid $grayFive;
          border-radius: 0.5rem;
          margin-bottom: 1rem;
          height: 100%;
          padding: 0.375rem 0;
          overflow: scroll;
          overflow-x: hidden;

          & .content__items {
            padding: 0.375rem 1rem;
            position: relative;
            transition: background-color 0.3s ease;
            &:hover {
              background-color: $grayEight;
            }

            & .content__item {
              display: flex;
              justify-content: space-between;

              & .group__icons {
                display: flex;
                align-items: center;

                & .group__icon {
                  display: flex;
                  align-items: center;
                }

                & .item__icon {
                  display: flex;
                  margin-left: 0.6rem;
                  position: relative;
                  z-index: 3;
                  cursor: pointer;

                  & svg path {
                    transition: fill 0.3s ease;
                  }
                  
                  &:hover svg path {
                    fill: $accentYellow;
                  }
                }
              }

              & > div {
                & input {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  display: flex;
                  left: 0;
                }
              }
            }

            & .content__item_all {
              & p {
                font-weight: 500;
              }
            }
          }

          & .empty_message {
            display: flex;
            width: 100%;
            justify-content: center;

            & p {
              font-size: 1rem;
            }
          }
        }
      }

      & .buttons {
        display: flex;
        gap: 0.5rem;

        & button {
          display: flex;
          align-items: center;
          justify-content: center;
          @include mixinPrimaryButton(
            0.7813rem 1.5rem,
            $grayFive,
            $accentDark,
            none,
            8px,
            100%,
            2.75rem
          );

          & p {
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: 600;
            width: max-content;
          }
        }

        & button:first-child {
          background-color: $buttonPrimary;
        }
      }
    }
  }

  &__container {
    position: relative;
    @include mixinAuthContainer(58.1875rem);
    max-width: 100%;

    & .operators__empty {
      display: flex;
      margin: 0.5rem 0;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: rgba(26, 26, 26, 0.5);
    }

    & form {
      position: relative;
    }

    & .back {
      display: flex;
      align-items: center;
      margin-bottom: 1.875rem;
      &_icon {
        background-color: $buttonPrimary;
        width: 2.25rem;
        height: 2.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        margin-right: 0.75rem;
      }

      &_text {
        & p {
          font-size: 1.25rem;
          font-weight: 500;
          color: $accentDark;
        }
      }
    }

    .detail {
      background-color: $white;
      border-radius: 1.25rem;
      padding: 1.5rem;
      box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.1);
      position: relative;
      width: 100%;
      max-width: 100%;
      z-index: 2;

      & .group__input {
        display: flex;

        & .operators__items_icon_edit {
          display: flex;
          margin-left: 0.5rem;
        }
      }

      &__title_header {
        border-bottom: 2px dashed $grayFour;
        padding-bottom: 1rem;
        display: flex;
        align-items: center;

        & .detail__title {
          font-size: 1.5rem;
          color: $accentDark;
          font-weight: 600;
        }

        & .operators__items_icon_edit {
          margin-left: 0.5rem;

          & button {
            display: flex;
          }
        }
      }

      & .employee__group {
        margin-top: 0.5rem;
        //& h2 {
        //  margin: 1rem 0 0.5rem 0;
        //  font-size: 1.25rem;
        //  font-weight: 500;
        //  color: $accentDark;
        //}

        &_title {
          font-weight: 400;
          color: $accentDark;
        }

        & p {
          font-weight: 400;
          color: rgba(26, 26, 26, 0.5);
        }

        &__list {
          display: flex;
          gap: 0.625rem;
          flex-wrap: wrap;
          overflow-y: scroll;
          margin-top: 0.5rem;
          max-height: 7.6rem;

          & .group__item {
            padding: 0.375rem 1rem;
            border: 1px solid $graySix;
            border-radius: 6.25rem;
            display: flex;
            align-items: center;
            gap: 0.875rem;
            cursor: pointer;

            & p {
              transition: color 0.2s ease;
            }

            &:hover p {
              color: $yellowLight;
            }

            &:hover svg path {
              fill: $accentYellow;
            }

            & .link {
              display: flex;
              align-items: center;

              &:hover .item__title {
                color: $accentYellow;
              }

              & .item__icon {
                display: flex;
                align-items: center;
                margin-left: 0.3rem;

                & svg {
                  width: 0.8rem;
                }

                & svg path {
                  transition: fill 0.3s ease;
                }
              }
            }

            & .employee__remove_icon {
              display: flex;
              align-items: center;
              flex-direction: column;

              & svg {
                cursor: pointer;

                &:hover {
                  & path {
                    stroke: $yellowLight;
                  }
                }
              }

              & svg path {
                transition: stroke 0.2s ease;
                stroke: $textTitle;
              }
            }

            & p {
              font-weight: 700;
              font-size: 1rem;
              color: $textTitle;
            }
          }
        }
      }

      & .members__button {
        margin-top: 0.5rem;
        & button {
          @include mixinPrimaryButton(
            '.75rem 1.125rem',
            $graySix,
            $accentDark,
            none,
            0.5rem,
            max-content,
            2.5rem,
            600
          );
          max-width: 100%;

          & p {
            text-transform: uppercase;
            font-size: 0.875rem;
          }
        }
      }

      & .group__description {
        margin-top: 1rem;
        //& h2 {
        //  margin: 1rem 0 0.5rem 0;
        //  font-size: 1.25rem;
        //  font-weight: 500;
        //  color: $accentDark;
        //}

        &_title {
          font-weight: 400;
          color: $accentDark;
        }
      }

      & .group__input {
        & input {
          padding: 0 0 0 0.3rem;
        }
      }

      & .group__item {
        display: flex;
        align-items: center;

        & p {
          font-size: 1rem;
        }

        & button {
          margin-left: 0.5rem;
        }
      }

      & .operators {
        &__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0.875rem 0;

          @media (max-width: 768px) {
            flex-direction: column;
          }
        }

        &__wrapper__title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          & .operators__title {
            //font-size: 1.25rem;
            //font-weight: 500;
            //color: $accentDark;
            font-weight: 400;
            color: $accentDark;

            &:not(:last-child) {
              margin-right: 1rem;
            }
          }

          @media (max-width: 768px) {
            margin-bottom: 0.875rem;
          }
        }

        & .operators__sort {
          display: none;
          font-size: 1rem;
          & input {
            font-size: 1rem;
            height: 0;
            width: 0;

            @media (max-width: 480px) {
              font-size: 0.8rem;
            }
          }
          @media (max-width: 480px) {
            font-size: 0.8rem;
          }

          @media (max-width: 768px) {
            display: flex;
            width: 100%;
          }
        }

        &__list {
          border: 1px solid $grayFive;
          border-radius: 0.625rem;
          margin-bottom: 1rem;

          &__header {
            border-top-left-radius: 0.625rem;
            border-top-right-radius: 0.625rem;
            padding: 0.5rem 2.875rem 0.5rem 1rem;
            display: flex;

            background-color: $graySix;
            & .header__item {
              flex: 1;
              color: $grayTwo;
              font-size: 1rem;

              & p {
                margin-right: 0.5rem;
              }

              & .item {
                display: flex;
                align-items: center;
                cursor: pointer;
                width: fit-content;
              }
            }
            @media (max-width: 768px) {
              display: none;
            }
          }

          & .operators__main {
            max-height: 13rem;
            height: 100%;
            overflow-y: scroll;
            & .operators__items {
              display: flex;
              position: relative;
              align-items: center;
              padding: 0.5rem 1rem;

              & .operators__item {
                  & p {
                    overflow-y: hidden;
                    max-width: 11rem;

                    &::-webkit-scrollbar {
                      height: 0.3rem;
                    }
                  }
              }

              // & > div:first-child {
              //   & div input {
              //     height: 0;
              //     width: 0;
              //   }
              // }

              &_icon {
                position: absolute;
                right: 0.9rem;
                display: flex;
                align-items: center;
                cursor: pointer;
                transform: translate(0%, -50%);
                top: 50%;

                & button {
                  display: flex;
                }
              }

              &_icon_edit {
                position: absolute;
                right: 3rem;
                display: flex;
                align-items: center;
                cursor: pointer;
                transform: translate(0%, -50%);
                top: 50%;

                & svg path {
                }

                & button {
                  display: flex;
                }
              }

              & input,
              & div {
                font-size: 0.875rem;
              }

              &:not(:last-child) {
                border-bottom: 1px solid $grayFive;
              }

              & > div {
                flex: 1;

                & p {
                  font-size: 1rem;
                  color: $accentDark;
                }
              }

              &_remove {
                position: absolute;
                right: 1.5625rem;

                & .remove__button {
                  display: flex;
                  cursor: pointer;
                }
              }

              @media (max-width: 768px) {
                flex-direction: column;
                border: 1px solid $grayFive;
                border-radius: 1rem;
                margin-bottom: 1rem;

                &_icon {
                  right: 1rem;
                  top: 1.5rem;
                }

                &_icon_edit {
                  top: 1.5rem;
                }

                & .operators__item {
                  width: 100%;

                  & .item__inner {
                    & h5 {
                      display: flex;
                      color: rgba($accentDark, 0.55);
                      font-size: 0.75rem;
                      font-weight: 400;
                    }

                    &:first-child p {
                      margin-right: 3.6rem;
                    }

                    & p {
                      overflow-y: hidden;
                      max-width: 100%;

                      &::-webkit-scrollbar {
                        height: 0.3rem;
                      }
                    }

                  }

                  & .item__inner:not(:last-child) {
                    margin-bottom: 0.8rem;
                  }

                  @media (max-width: 768px) {
                    & .operators__placeholder {
                      display: none;
                    }
                  }

                  @media (max-width: 480px) {
                    & .item__inner {
                      & p {
                        font-size: 0.8rem;
                      }
                    }
                  }
                }
              }

              @media (max-width: 1024px) {
                &_icon {
                  right: 1rem;
                }
              }
            }

            & .operators__items_edit {
              padding: 0.5rem 0 0.5rem 0;
              border: none;

              & .operators__items_icon {
                right: 0;
              }
              & .operators__items_icon_edit {
                right: 2rem;
              }
            }

            & .operators__items__create {
              flex-direction: row;
              flex-wrap: wrap;
              align-items: center;

              & .operators__items_icon {
              }

              @media (max-width: 1024px) {
                & > .operators__item:nth-child(4) {
                }
              }

              @media (max-width: 768px) {
                padding-top: 2.5rem;
                & > .operators__item:nth-child(4) {
                  margin-right: 0;
                }

                & .operators__placeholder {
                  display: none;
                }

                & .operators__item_dynamic_amount {
                  order: 1;
                }
              }

              @media (max-width: 768px) {
                gap: 0.5rem;
              }

              @media (max-width: 480px) {
                flex-direction: column;

                & .operators__item {
                  width: 100%;
                }
              }
            }

            @media (max-width: 768px) {
              padding-right: 0.2rem;
            }
          }
          @media (max-width: 768px) {
            border: none;
            border-radius: 0;
          }
        }

        @media (max-width: 480px) {
          &__header {
            flex-direction: column;
            align-items: flex-start;

            & .operators__sort {
              width: 100%;
            }

            & .operators__wrapper__title {
              margin-bottom: 1.5rem;
            }
          }
        }
      }

      & .operators__interactive {
        display: flex;
        justify-content: space-between;
        & .operators__button_add {
          & button {
            @include mixinPrimaryButton(
              0.75rem 1.6875rem,
              $accentYellow,
              $accentDark,
              none,
              8px,
              6.0625rem,
              2.5rem,
              0
            );
          }

          & p {
            font-weight: 600;
            text-transform: uppercase;
          }
        }

        & .operators__buttons {
          display: flex;
          gap: 0.75rem;

          & .operators__button:first-child {
            & button {
              background-color: $accentYellow;
            }
          }

          & .operators__button {
            & button {
              @include mixinPrimaryButton(
                0.75rem 1.6875rem,
                $grayFive,
                $accentDark,
                none,
                8px,
                9rem,
                2.5rem,
                0
              );
            }

            & p {
              font-weight: 600;
              font-size: 0.875rem;
              color: $accentDark;
              text-transform: uppercase;
            }
          }
        }

        @media (max-width: 768px) {
          & .operators__buttons {
            & .operators__button {
              & button {
                width: 7rem;
              }
            }
          }
        }

        @media (max-width: 480px) {
          flex-direction: column;

          & .operators__buttons {
            margin-top: 0.75rem;
            flex-wrap: wrap;
            gap: 0.75rem;

            & .operators__button {
              width: 100%;
              & button {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.paginate__count__mobile {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  padding: 0.3rem 1rem 0rem 1rem;

  & .count__divider {
    padding: 0 0.3rem;
  }
}
