@import 'styles/vars.scss';

.wrapper {
  position: absolute;
  z-index: 10;
  top: 110%;
  background-color: $white;
  border-radius: 10px;
  transform: translate(-50%, 0);
  left: 50%;
  box-shadow: rgba(0, 0, 0, 0.11) 0px 0px 8px;
  overflow: hidden;

  & ul {
    display: flex;
    flex-direction: column;
    max-height: 6.9375rem;
    overflow-y: auto; /* Используйте "auto" вместо "scroll" */

    /* Применяйте стили к скроллбару и месту под ним только в случае,
       если высота блока больше 220px */
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;

    & button {
      //border-radius: 10px;
    }

    & li {

    }

    & li:first-child {
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }

    & li:last-child {
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }
}
