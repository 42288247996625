@import 'styles/vars';

.wrapper {
  width: 44.44px;
  min-width: 44.44px;
  height: 20px;
  background-color: rgba($grayFour, 0.4);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 50px;
  padding: 0 5px;
  cursor: pointer;

  &:disabled {
    opacity: 1;
  }

  & .handle {
    width: 20px;
    height: 13.33px;
    background-color: white;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.active {
  justify-content: flex-end;
}
