@import 'styles/vars';
@import 'styles/mixins';

.wrapper {
  //width: 44.44px;
  //min-width: 44.44px;
  //height: 20px;
  //background-color: rgba($grayFour, 0.4);
  //display: flex;
  //justify-content: flex-start;
  //align-items: center;
  //border-radius: 50px;
  //padding: 0 5px;
  //cursor: pointer;

  & .title {
    font-size: 14px;
    color: #1a1a1a;
    font-weight: 500;
    margin-bottom: 0.3rem;
  }

  & .btn {
    display: flex;
    align-items: center;
    position: relative;

    @include mixinPrimaryButton(
                    0.5rem,
                    #F8FAFC,
                    $accentDark,
                    1px solid $grayEight,
                    8px,
                    100%,
                    2.25rem,
                    500
    );

    & p {
      font-size: 1rem;
      color: $accentDark;

      @media (max-width: 480px) {
        font-size: 0.8rem;
      }
    }

    & .icon {
      display: flex;
      position: absolute;
      right: 0.5rem;
    }
  }
}
