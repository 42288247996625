@import 'styles/vars.scss';
.loader__wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    
  .loader {
    border: 3px solid $borderPrimary;
    border-top: 3px solid $accentYellow;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.5s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}