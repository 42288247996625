.wrapper {

  & .calendar {
    overflow: hidden;
    border-radius: 0;
    border-bottom: none;
    width: 100%;
    border-color: transparent;
    background-color: transparent;

    //& .react-calendar__tile--active:enabled {
    //  background: red;
    //}
  }

  & .time {
    max-width: 100%;
    border-radius: 0;
    border: 1px solid transparent;
    background-color: transparent;
  }
}
