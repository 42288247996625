@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.wrapper {
  padding: 3.3125rem 6rem 2.125rem 1rem;
  display: flex;
  justify-content: center;
  background-color: $graySix;
  width: 100%;
  height: auto;
  position: relative;

  & > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    @media (max-width: 1024px) {
      height: auto;
    }
  }

  &__container {
    @include mixinAuthContainer(70.375rem);
    max-width: 100%;
    min-height: 100%;
    height: 100%;

    & .back {
      display: flex;
      align-items: center;
      margin-bottom: 1.875rem;
      &_icon {
        background-color: $buttonPrimary;
        width: 2.25rem;
        height: 2.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        margin-right: 0.75rem;
      }

      &_text {
        & p {
          font-size: 1.25rem;
          font-weight: 500;
          color: $accentDark;
        }
      }
    }

    & .wrapper__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.6875rem;
      & .title {
        color: $accentDark;
        font-size: 2rem;
        font-weight: 600;
      }

      & .header__group__button {
        display: flex;
        align-items: center;

        & .group__info {
          position: relative;

          & button {
            display: flex;
            align-items: center;
            transition: border-radius, width 0.5s ease;
            @include mixinPrimaryButton(
              0.6875rem 0.8125rem,
              $grayFive,
              $textTitle,
              none,
              8px,
              100%,
              3rem,
              0
            );

            & p {
              font-weight: 600;
              font-size: 1rem;
              margin: 0 0.6875rem;
            }

            & .group__icon,
            & .group__icon_drop {
              display: flex;
              align-items: center;
              transition: transform 0.2s ease;
            }

            & .group__icon_drop_open {
              transform: rotate(180deg);
            }
          }

          & .group__button_border_change {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }

          & .group__additional_info {
            transition: all 0.3s ease;
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            z-index: 2;
            overflow: hidden;
            width: 100%;
            background-color: $grayFive;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            opacity: 0;
            gap: 0.1rem;
            padding: 0.2rem;

            & p {
              font-size: 1rem;
            }
          }

          & .group__additional_info_show {
            opacity: 1;
          }
        }

        & .link__icon {
          display: flex;
          margin-left: 0.6rem;
          cursor: pointer;

          & svg path {
            transition: fill 0.3s ease;
          }

          &:hover svg path {
            fill: $accentYellow;
          }
        }
      }

      & .wrapper__button {
        display: flex;
        & button {
          @include mixinPrimaryButton(
            1rem 3.6875rem,
            $accentYellow,
            $accentDark,
            none,
            8px,
            max-content,
            3rem,
            600
          );
          transition: all 0.3s ease;
          text-transform: uppercase;
          align-items: center;
          display: flex;
          gap: 0.5rem;

          & .button__icon {
            display: flex;
            align-items: center;
          }

          & p {
            transition: color 0.3s ease;
            font-size: 0.875rem;
            text-transform: uppercase;
          }

          &:disabled {
            background-color: $grayFive;
            color: $grayThree;
          }
        }

        @media (max-width: 768px) {
          display: none;
        }
      }

      @media (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;

        & .title {
          margin-bottom: 1rem;
        }
      }
    }

    & .select__provider__mobile {
      display: flex;

      width: 100%;
      margin: 1rem 0;
      display: none;
      gap: 1rem;

      & .select__provider {
        width: 100%;
        max-width: 100%;
      }

      & .select__provider__wrapper {
        width: 100%;
        position: relative;

        & input {
          font-size: 1rem;

          &::placeholder {
            font-size: 1rem;
          }
        }
      }

      @media (max-width: 1024px) {
        display: flex;
        align-items: center;

        & .select__provider {
          width: 100%;
        }
      }

      & .providers__menu {
        max-height: 10rem;
        position: absolute;
        z-index: 6;
        background-color: $white;
        width: 100%;
        & .choose__option {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1rem 0;

          & .choose__icon {
            display: flex;

            & svg {
              cursor: pointer;
            }
            & svg path {
              stroke: $grayFour;
            }
          }
        }
      }
    }

    .pay__arrows__mobile {
      justify-content: flex-end;
      display: flex;

      & .pay__arrows {
        width: 3.5rem;
        height: 2.5rem;
        border: 1px solid $backgroundGrayExtraLight;
        border-radius: 0.5rem;
        display: flex;

        & > div {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          cursor: pointer;

          &:hover {
            background-color: $backgroundGrayAlmostWhite;
          }
        }
      }
    }

    & .search {
      display: flex;
      position: relative;
      margin-bottom: 1rem;

      & > div:first-child {
        width: 100%;
      }

      & input {
        padding-left: 3.25rem;
        height: 3rem;
        width: 100%;
        background-color: $white;
        color: $grayThree;
      }

      & .search__icon {
        position: absolute;
        left: 1.25rem;
        top: 50%;
        transform: translate(0, -50%);
        cursor: pointer;
        height: 1.25rem;
      }
    }

    .pay__wrapper {
      position: relative;

      & .operator__disabled + button {
        display: none !important;
      }

      .pay__scrollbar_modified {
        &::-webkit-scrollbar:horizontal {
          width: 1rem;
          height: 0.6rem;
        }

        &::-webkit-scrollbar:vertical {
          width: 1rem;
        }

        &::-webkit-scrollbar-thumb:vertical {
          border: 5px solid rgba(0, 0, 0, 0);
          background-clip: padding-box;
          border-radius: 50px;
        }

        &::-webkit-scrollbar-thumb:horizontal {
          border: 2px solid rgba(0, 0, 0, 0);
          background-clip: padding-box;
          border-radius: 50px;
        }

        &::-webkit-scrollbar-track {
          margin-top: 3.25rem;
          margin-left: 11.726rem;
        }
        overflow-y: scroll;
      }

      .pay {
        border-top-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        background-color: $grayFive;
        max-width: 100%;
        max-height: 33.5625rem;
        // overflow-y: hidden;
        overflow-x: hidden;

        &::-webkit-scrollbar:horizontal {
          height: 0.6rem;
        }

        &::-webkit-scrollbar-track:horizontal {
          margin-top: 3.25rem;
          margin-left: 11.726rem;
        }

        &::-webkit-scrollbar-thumb:horizontal {
          border: 2px solid rgba(0, 0, 0, 0);
          background-clip: padding-box;
          border-radius: 50px;
        }

        &__header {
          display: flex;
          height: 3.25rem;
          width: fit-content;

          position: sticky;
          top: 0;
          background-color: #e0e0e0;
          z-index: 8;

          & .small_width {
            min-width: 11.5625rem;
            max-width: 11.5625rem;
          }

          & .large_width {
            min-width: 11.726rem;
            max-width: 11.726rem;
          }

          .select__provider__wrapper {
            width: 100%;
            padding: 0 0.5rem;

            & > div {
              display: flex;
              justify-content: center;
            }
          }

          & .small_width {
            min-width: 11.5625rem;
            max-width: 11.5625rem;
          }

          & .large_width {
            min-width: 11.726rem;
            max-width: 11.726rem;
          }

          & > div {
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 4px 8px -2px rgb(0 0 0 / 13%);
          }

          & > div:first-child {
            background-color: $backgroundPrimary;
            border-top-left-radius: 0.5rem;
            color: $graySix;
            border-bottom: 1px solid $grayFour;
            position: sticky;
            z-index: 3;
            left: 0;

            & p {
              opacity: 0.5;
              margin-left: 1.125rem;
            }
          }

          & > div:not(:first-child) {
            text-transform: uppercase;
            font-size: 0.875rem;
            border-right: 1px solid $grayFour;
          }

          &_item {
            flex: 1;
            font-size: 1rem;

            &:not(:first-child) {
              padding-left: 0.3rem;
            }

            & .item {
              display: flex;
              width: 100%;
              align-items: center;
              height: 100%;
              //overflow-y: hidden;
              //
              //&::-webkit-scrollbar {
              //  height: 0.3rem;
              //}

              & p {
                overflow-y: hidden;
                margin: 0 0.5rem;

                &::-webkit-scrollbar {
                  height: 0.3rem;
                }
              }

              & p:not(:first-child) {
                font-size: 0.875rem;
              }

              & .item__buttons {
                display: flex;
                align-items: center;
                margin-left: auto;

                & .item__button {
                  display: flex;
                }

                & svg {
                  cursor: pointer;
                }

                & svg:first-child {
                  margin-right: 0.8rem;
                }
              }
            }
          }

          @media (max-width: 480px) {
            & .small_width {
              min-width: 9.5625rem;
              max-width: 9.5625rem;
            }

            & .large_width {
              min-width: 9.726rem;
              max-width: 9.726rem;
            }
          }
        }

        &__main {
          & .pay__items {
            display: flex;
            width: fit-content;

            & .pay__item_scroll {
              position: relative;

              & .pay__item {
                position: absolute;
                top: calc(50% + 0.3rem);
                transform: translate(0, calc(-50% - 0.3rem));

                & .link {
                  display: flex;

                  &:hover .item__icon svg path {
                    fill: $accentYellow;
                  }

                  & .fullname {
                  }

                  &:hover .item__title {
                    color: $accentYellow;
                  }

                  & .item__icon {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-left: 1.125rem;
                    margin-right: 0.3rem;

                    & p {
                      margin-left: 0;
                      margin-right: 0.3rem;
                    }

                    & svg {
                      width: 0.8rem;
                    }

                    & svg path {
                      transition: fill 0.3s ease;
                      fill: $white;
                    }
                  }
                }
              }

              &::-webkit-scrollbar {
                height: 0.3rem;
              }

              &::-webkit-scrollbar-thumb {
                background-color: $grayFour;
                border-radius: 3.125rem;
              }

              &::-webkit-scrollbar-track {
                border-radius: 3.125rem;
              }

              overflow-y: hidden;
            }

            // TODO: Редактировать скроллбар
            // & .pay__item {
            //   overflow-x: scroll;

            & .pay__item {
              cursor: pointer;

              & p {
                transition: color 0.2s ease;
              }

              &:hover p {
                color: $yellowLight;
              }
            }

            & .small_width {
              min-width: 11.5625rem;
              max-width: 11.5625rem;
            }

            & .large_width {
              min-width: 11.726rem;
              max-width: 11.726rem;
            }

            &:nth-child(odd) > div:first-child {
              background-color: #2d3b43;
            }

            & > div:not(:first-child) {
              padding: 0 1rem;
            }

            &:nth-child(odd) > div:not(:first-child) {
              background-color: rgba(0, 0, 0, 0.2);
            }

            & > div:first-child {
              justify-content: flex-start;
              font-size: 1rem;
              color: $graySix;
              background-color: $backgroundPrimary;
              font-weight: 600;
              position: sticky;
              left: 0;
              z-index: 3;

              & p {
                margin-left: 1.125rem;
              }
            }

            & > div:not(:first-child) {
              border-right: 1px solid $grayFour;
            }

            & > div {
              height: 6.0625rem;
              display: flex;
              align-items: center;
              justify-content: center;
              border-bottom: 1px solid $grayFour;
            }

            & .pay__create {
              input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
              }

              input[type='number'] {
                -moz-appearance: textfield; /* Firefox */
              }

              & .small_width {
                min-width: 11.5625rem;
                max-width: 11.5625rem;
              }

              & .large_width {
                min-width: 11.726rem;
                max-width: 11.726rem;
              }

              & > div + button {
                display: none;
              }

              .select__provider__wrapper {
                width: 100%;
                & > div {
                  display: flex;
                  justify-content: center;
                }
              }

              & .select__provider_edit {
                cursor: pointer;
              }

              & > div {
                width: 100%;
              }

              & > .text__provider__wrapper:not(:first-child) {
                display: none;
              }

              & .duplicate__buttons {
                display: flex;
                & > button {
                  display: none;
                }
              }

              & button {
                display: flex;
                align-items: center;
                justify-content: center;
                @include mixinPrimaryButton(0, transparent, none, none, 8px, 9.75rem, 3rem, 600);
                transition: color 0.3s ease;
                text-transform: uppercase;

                & p {
                  transition: color 0.3s ease;
                  font-size: 0.875rem;
                  text-transform: uppercase;
                  color: $grayThree;
                  margin-right: 0.3987rem;
                }

                &:hover p {
                  color: $accentYellow;
                }

                &:hover svg {
                  stroke: $accentYellow;
                }

                & svg {
                  width: 0.7081rem;
                  height: 0.7081rem;
                  stroke: $grayThree;
                  transition: stroke 0.3s ease;

                  & path {
                    stroke-width: 3.1;
                  }
                }
              }

              @media (max-width: 480px) {
                & .small_width {
                  min-width: 9.5625rem;
                  max-width: 9.5625rem;
                }

                & .large_width {
                  min-width: 9.726rem;
                  max-width: 9.726rem;
                }
              }
            }

            & .pay__edit {
              & input:disabled {
                cursor: pointer;
                opacity: 1;
              }
            }

            @media (max-width: 768px) {
              & > div:not(:first-child) {
                padding: 0 0.5rem;
              }

              & > div {
                height: 3.875rem;
              }
            }

            @media (max-width: 480px) {
              & .small_width {
                min-width: 9.5625rem;
                max-width: 9.5625rem;
              }

              & .large_width {
                min-width: 9.726rem;
                max-width: 9.726rem;
              }
            }
          }
        }

        & .pay__create_employee {
          position: absolute;
          left: 0;
          bottom: -3.25rem;

          & .small_width {
            min-width: 11.5625rem;
            max-width: 11.5625rem;
          }

          & .large_width {
            min-width: 11.726rem;
            max-width: 11.726rem;
          }

          & button {
            display: flex;
            align-items: center;
            justify-content: center;
            @include mixinPrimaryButton(
              1.125rem 4.875rem,
              $accentYellow,
              $textPrimary,
              auto,
              0 0rem 0.5rem 0.5rem,
              none,
              3.25rem
            );

            & .pay__create_icon {
              display: flex;
            }

            & svg {
              stroke: $textTitle;
              & path {
                stroke-width: 2.5;
              }
            }
          }

          @media (max-width: 1024px) {
            bottom: -3.25rem;
          }

          @media (max-width: 480px) {
            & button {
              padding: 1.125rem 3.875rem;
            }

            & .small_width {
              min-width: 9.5625rem;
              max-width: 9.5625rem;
            }

            & .large_width {
              min-width: 9.726rem;
              max-width: 9.726rem;
            }
          }
        }

        & .pay__create_operator {
          position: absolute;
          right: -4.925rem;
          top: 0;

          & button {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease;
            @include mixinPrimaryButton(
              1.0625rem 2rem,
              $accentYellow,
              $textPrimary,
              none,
              0 0.5rem 0.5rem 0,
              4.9375rem,
              3.25rem
            );

            &:disabled {
              background-color: rgba($grayFour, 1);
              opacity: 1;
            }

            & .pay__create_icon {
              display: flex;
            }

            & p {
              font-size: 1.25rem;
            }

            & svg {
              stroke: $textTitle;

              & path {
                stroke-width: 2.5;
              }
            }
          }

          @media (max-width: 1024px) {
            display: none;
          }
        }

        & .pay__create_rotate {
          right: -3.925rem;
        }

        @media (max-width: 1024px) {
          overflow-x: hidden;
        }

        @media (max-width: 480px) {
          &::-webkit-scrollbar-track:horizontal {
            margin-left: 9.726rem;
          }
        }
      }

      & .pay__arrows {
        position: absolute;
        width: 3.5rem;
        height: 2.5rem;
        right: -3.8rem;
        top: 4.9375rem;
        border: 1px solid $backgroundGrayExtraLight;
        border-radius: 0.5rem;
        display: flex;
        overflow: hidden;

        & > div {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          cursor: pointer;

          &:hover {
            background-color: $backgroundGrayAlmostWhite;
          }
        }

        @media (max-width: 1024px) {
          display: none;
        }
      }

      @media (max-width: 768px) {
        & .pay__scrollbar_modified {
          &::-webkit-scrollbar:vertical {
            width: 0.6rem;
          }

          &::-webkit-scrollbar-thumb:vertical {
            border: 2px solid rgba(0, 0, 0, 0);
          }
        }
      }

      @media (max-width: 480px) {
        & .pay__scrollbar_modified {
          &::-webkit-scrollbar {
            width: 0.3rem;
          }
        }
      }
    }

    .wrapper__button_mobile {
      display: none;
      margin-top: 5rem;
      width: 100%;

      & .wrapper__button {
        width: 100%;
      }

      & button {
        @include mixinPrimaryButton(
          1rem 3.6875rem,
          $accentYellow,
          $accentDark,
          none,
          8px,
          100%,
          3rem,
          600
        );
        transition: all 0.3s ease;
        text-transform: uppercase;
        align-items: center;
        justify-content: center;
        display: flex;
        gap: 0.5rem;

        & .button__icon {
          display: flex;
          align-items: center;
        }

        & p {
          transition: color 0.3s ease;
          font-size: 0.875rem;
          text-transform: uppercase;
        }

        &:disabled {
          background-color: $grayFive;
          color: $grayThree;
        }
      }

      @media (max-width: 768px) {
        display: flex;
      }
    }

    @media (max-width: 1244px) {
      width: 58.65rem;
    }

    @media (max-width: 1057px) {
      width: 46.925rem;
    }

    @media (max-width: 789px) {
      width: 35.2rem;
    }

    @media (max-width: 602px) {
      width: 23.475rem;
    }

    @media (max-width: 480px) {
      width: 19.38rem;
    }
  }

  @media (max-width: 1024px) {
    padding: 3.3125rem 1rem 4.125rem 1rem;
  }
}

.test__popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.additional__select {
  position: fixed;
  width: 100px;
  height: 100px;
  left: 0;
  top: 0;
}

.select__mobile__icon {
  position: relative;
  z-index: 20;
  display: flex;
  align-items: center;

  &_button {
    display: flex;
  }
}

.select__mobile_service {
  margin: 0.5rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &_button {
    @include mixinPrimaryButton(0.625rem 0, $accentYellow, $accentDark, none, 0.5rem, 15.625rem);
    max-width: 100%;
    & p {
      font-weight: 600;
      font-size: 1rem;
      color: $accentDark;
    }

    &:disabled {
      background-color: $grayFive;
      color: $grayThree;
    }
  }
}

.input__error_label {
  position: absolute;
  top: 0;
}

.text__provider__wrapper {
  position: relative;
}

.paginate__count__mobile {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  padding: 0.3rem 1rem 0rem 1rem;

  & .count__divider {
    padding: 0 0.3rem;
  }
}

.select__search {
  display: flex;
  position: relative;
  margin: 0.3rem 0 0.3rem 0;
  padding: 0 0.3rem;

  & > div:first-child {
    width: 100%;
  }

  & input {
    padding-left: 3.25rem;
    height: 2.75rem;
    width: 100%;
    background-color: $white;
    color: $grayThree;
  }

  & .search__icon {
    position: absolute;
    left: 1.25rem;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
    height: 1.25rem;
  }
}
