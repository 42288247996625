@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.wrapper {
  background-color: $backgroundPrimary;
  height: 3.9375rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    @include mixinAuthContainer(100%);
    padding: 0 6.75rem;

    & .wrapper__buttons_inner {
      & .wrapper__button {
        height: 100%;

      & svg,
      & svg path {
          transition: all .3s ease;
        }
      }
    }

    & .more_btn_inner {
      position: relative;
    }

    & .more_btn_active .button_more svg {
     transform: rotate(180deg);
    }

    & .burger {
      display: none;

      &__button {
        display: flex;
        cursor: pointer;
      }

      @media (max-width: 1200px) {
        display: flex;
      }
    }

    & .profile {
      display: flex;
      align-items: center;
      position: relative;

      &__balance {
        display: flex;
        align-items: center;
        @include mixinPrimaryButton(
                        0.5rem 1rem,
                        $backgroundPrimaryDark,
                        $white,
                        none,
                        0.5rem,
                        auto,
                        3rem,
                        500
        );
        margin-right: 1rem;

        & p {
          color: $white;
          font-weight: 500;
          font-size: 0.875rem;
        }

        @media (max-width: 1200px) {
          display: none;
        }
      }

      &__more {
        margin-right: 1rem;
        position: relative;

        &_btn {
          display: flex;
          align-items: center;
          transition: all .3s ease;
          @include mixinPrimaryButton(
                          0.5rem 0.5rem,
                          $backgroundPrimaryDark,
                          $white,
                          1px solid transparent,
                          0.5rem,
                          fit-content,
                          auto,
                          500
          );

          & p {
            margin-right: 0.3rem;
            color: $grayFour;
            transition: color .3s ease;
          }

          & svg path {
            transition: fill .3s ease;
            fill: $grayFour;
          }

          & .arrow {
            transition: transform .3s ease;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          & .arrow_active {
            transform: rotate(180deg);
          }

          &:hover {
            background-color: $backgroundGrayAlmostWhite;
            border: 1px solid $white;
          }

          &:hover p {
            color: $white;
          }

          &:hover svg path {
            fill: $white;
          }
        }

        &_active {
          background-color: $backgroundGrayAlmostWhite;
          border: 1px solid $white;

          & p {
            color: $white;
          }

          & svg path {
            fill: $white;
          }
        }

        @media (max-width: 1200px) {
          display: none;
        }
      }

      &__wrapper {
        position: relative;
        border-radius: 0.5rem;

        & .profile__button {
          display: flex;
          align-items: center;
          @include mixinPrimaryButton(
                          0.5rem 1rem,
                          $backgroundPrimaryDark,
                          $white,
                          none,
                          0.5rem,
                          fit-content,
                          3rem,
                          500
          );

          & .profile__icon {
            margin-right: 0.625rem;
            display: flex;

            & svg {
              width: 2rem;
              height: 2rem;
            }
          }

          & p {
            margin-right: 0.9375rem;
            color: $white;
            font-weight: 500;
            font-size: 0.875rem;
          }

          & .arrow {
            transition: transform .3s ease;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          & .arrow_active {
            transform: rotate(180deg);
          }
        }

        &__list {
          position: absolute;
        }

        & .profile__list {
          position: absolute;
          display: flex;
          align-items: center;
          flex-direction: column;
          background-color: rgba($backgroundPrimaryDark, 0.5);
          z-index: 10;
          width: 100%;
          border-radius: 0.5rem;
          color: $white;
          overflow: hidden;

          & button {
            width: 100%;
            height: 100%;
            color: $white;
            padding: 0.5rem 0;
            transition: background-color 0.3s ease;

            &:hover {
              background-color: rgba($backgroundPrimaryDark, 0.7);
            }
          }

          & .balance {
            padding: 1rem 0;
            background-color: $backgroundPrimaryDark;
            width: 100%;
            text-align: center;
            display: none;
          }

          & .logout {
          }

          @media (max-width: 1200px) {
            & .balance {
              display: block;
            }
          }
        }
      }

      @media (max-width: 768px) {
        & .profile__button {
          @include mixinPrimaryButton(
                          0,
                          transparent,
                          transparent,
                          none,
                          0,
                          fit-content,
                          fit-content,
                          500
          );

          & .profile__icon {
            margin: 0;
          }

          & .arrow,
          & .arrow_active,
          & p {
            display: none;
          }
        }

        & .profile__list {
          top: 2.9rem;
          right: -50%;
          width: auto;

          & .balance {
            padding: 1rem;
          }
        }
      }

      @media (max-width: 1200px) {
        &__balance {
          margin-right: 0.5rem;
        }
      }
    }

    @media (max-width: 1400px) {
      padding: 0 1rem;
    }
  }

  &__logo {
    height: 100%;
    display: flex;
    align-items: center;

    & button {
      display: flex;

      & svg {
        width: 6.25rem;
        max-height: 100%;
        height: inherit;
        cursor: pointer;
      }
    }
  }

  &__buttons {
    display: flex;
    height: 100%;
    gap: 2rem;

    & .wrapper__button {
      & .button {
        display: flex;
        align-items: center;
        height: 100%;

        & p {
          margin-left: 0.75rem;
          text-transform: uppercase;
          font-size: 0.875rem;
          font-weight: 500;
          color: $grayFour;
          transition: color 0.3s ease;
          width: max-content;
        }

        &_active {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          position: relative;
          display: grid;
          grid-template-columns: 1.4rem 1fr;

          & svg path {
            fill: $buttonPrimary;
          }

          & p {
            color: $white;
          }

          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: $buttonPrimary;
            border-radius: 1px;
          }
        }

        &_default {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          position: relative;
          display: grid;
          grid-template-columns: 1.4rem 1fr;

          & svg path {
            transition: fill 0.3s ease;
            fill: $grayFour;
          }

          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            border-radius: 1px;
            transition: background 0.3s ease;
          }

          &:hover {
            &::before {
              background: $buttonPrimary;
            }

            & svg path {
              fill: $white;
            }

            p {
              color: $white;
            }
          }
        }
      }

      & .button_more {
        //display: none;
      }
    }

    & > div:nth-child(3) {
      & .button_default {

        & svg path {
          transition: all 0.3s ease;
          fill: transparent;
          stroke: $grayFour;
          stroke-width: 1;
        }

        &:hover {
          & svg path {
            fill: transparent;
            stroke: $white;
          }
        }
      }

      & .button_active {
        & svg path {
          stroke: $buttonPrimary;
          stroke-width: 1;
          fill: transparent;
        }
      }
    }

    @media (max-width: 1200px) {
      gap: 1rem;
      display: none;
    }
  }

  &__buttons_modal {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: flex-start;

    & .wrapper__button {
      width: 100%;
      position: relative;

      & .button {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 1rem 0;

        & svg {
          transition: all .3s ease;
          width: 24px;
          height: 24px;
        }

        & p {
          margin-left: 0.75rem;
          text-transform: uppercase;
          font-size: 0.875rem;
          font-weight: 500;
          color: $accentDark;
          transition: color 0.3s ease;
        }

        &_active {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          position: relative;
          display: grid;
          grid-template-columns: 1.4rem 1fr;

          & svg path {
            fill: $buttonPrimary;
          }

          & p {
            color: $accentYellow;
          }

          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: $buttonPrimary;
            border-radius: 1px;
          }
        }

        &_default {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          position: relative;
          display: grid;
          grid-template-columns: 1.4rem 1fr;

          & svg path {
            transition: all 0.3s ease;
            fill: $accentDark;
          }

          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            border-radius: 1px;
            transition: background 0.3s ease;
          }

          &:hover {
            &::before {
              background: $buttonPrimary;
            }

            & svg path {
              fill: $accentYellow;
            }

            p {
              color: $accentYellow;
            }
          }
        }
      }
    }

    & .specific_icon {

      & .button_default:hover {
        & svg path {
          stroke: $accentYellow !important;
        }

        p {
          color: $accentYellow !important;
        }
      }

      & .button_active {
        & svg path {
          stroke: $accentYellow !important;
        }

        p {
          color: $accentYellow !important;
        }
      }

      & svg path {
        fill: transparent !important;
        stroke-width: 1;
        stroke: $accentDark;
      }
    }

    & .wrapper__button_list {

      & > button {
        & svg path {
          fill: $accentYellow !important;
        }

        & svg {
          transform: rotate(180deg);
        }

        & p {
          color: $accentYellow !important;
        }

        &::before {
          background-color: $accentYellow;
        }
      }

      & li svg path {

      }
    }

    & .more_btn {
      display: none;

      @media (max-width: 1200px) {
        display: block;
      }
    }

    & .default_icon {

      & .button_default {

      }

      & .button_default:hover {
        & svg path {
          stroke: none !important;
          fill: $accentYellow !important;
        }

        p {
          color: $accentYellow !important;
        }
      }

      & .button_active {
        & svg path {
          stroke: none !important;
          fill: $accentYellow !important;
        }

        p {
          color: $accentYellow !important;
        }
      }
    }
  }

  & .employees_active {
    & > div:first-child {
      & button {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        display: grid;
        grid-template-columns: 1.4rem 1fr;

        & svg path {
          fill: $buttonPrimary;
        }

        & p {
          color: $white;
        }

        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: $buttonPrimary;
          border-radius: 1px;
        }

        &:hover {
          & svg path {
            fill: $buttonPrimary;
          }
        }
      }
    }
  }

  & .employees_active_modal {
    & p {
      text-align: left;
    }

    & > div:first-child {
      & button {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        display: grid;
        grid-template-columns: 1.4rem 1fr;

        & svg path {
          fill: $buttonPrimary;
        }

        & p {
          color: $accentYellow;
        }

        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: $buttonPrimary;
          border-radius: 1px;
        }

        &:hover {
          & svg path {
            fill: $buttonPrimary;
          }
        }
      }
    }
  }

  & .employee__window {
    background-color: $white;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    height: -webkit-fill-available;
    z-index: 10;
    max-width: 100%;

    &__inner {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      & .wrapper__buttons_modal {
        & p {
          text-align: left;
        }

        //& > div:last-child {
        //  & .button_default {
        //
        //    & svg path {
        //      transition: all 0.3s ease;
        //      fill: transparent;
        //      stroke: $accentDark;
        //      stroke-width: 1.5px;
        //    }
        //
        //    &:hover {
        //      & svg path {
        //        fill: transparent;
        //        stroke: $accentYellow;
        //      }
        //    }
        //  }
        //
        //  & .button_active {
        //    & svg path {
        //      stroke: $buttonPrimary;
        //      fill: transparent;
        //    }
        //  }
        //}
      }

      & .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: max-content;
        width: 100%;

        &__title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 1rem;
          width: 100%;

          & .title {
            font-weight: 600;
            font-size: 1.5rem;
            color: $accentDark;
          }
        }

        & .close {
          display: flex;

          & button {
            display: flex;

            & svg {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
