@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.wrapper__container {
  background-color: $graySix;
  border-radius: 0.75rem;
  @include mixinAuthContainer(24.875rem, 1.75rem 1.5rem);
  max-width: 100%;

  & .close {
    display: flex;
    justify-content: flex-end;

    & svg {
      cursor: pointer;
    }
  }

  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    &__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      & .title {
        font-weight: 600;
        font-size: 1.5rem;
        color: $accentDark;
      }

      &_icon {
        display: flex;
        background-color: $accentYellow;
        padding: 1.5625rem;
        border-radius: 50%;
        margin-bottom: 1rem;
      }
    }
  }

  & .main {
    width: 100%;
    & .description {
      margin-bottom: 1rem;
      &__text {
        font-size: 0.875rem;
        color: $accentDark;
        max-width: 100%;
        text-align: center;

        & .text_weight {
          font-weight: 700;
        }
      }
    }

    & .employee {
      &__create {
        max-width: 100%;
        margin-bottom: 1rem;

        & input {
          height: 2.75rem;
        }

        &_label {
          color: $accentDark;
        }
      }
      & .buttons {
        display: flex;
        gap: 0.5rem;
        width: 100%;

        & button {
          display: flex;
          align-items: center;
          justify-content: center;
          @include mixinPrimaryButton(
                          0.7813rem 1.5rem,
                          $buttonPrimary,
                          $accentDark,
                          none,
                          8px,
                          100%,
                          2.25rem
          );

          & p {
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: 600;
            width: max-content;
          }
        }

        & button:last-child {
          background-color: $grayFive;
        }

        @media (max-width: 360px) {
          flex-direction: column;
        }

        @media (max-width: 480px) {
          width: 100%;

          & button {
            padding: 0 0.3rem;
            width: 100%;
          }
        }
      }
    }

    @media (max-width: 480px) {
      width: 100%;
    }
  }
}
