@import "styles/vars.scss";
@import "styles/mixins.scss";

.wrapper {
  width: 100%;
  border: 2px dashed #aaa;
  padding: 0.7rem;
  border-radius: 8px;

  & .file__wrapper {
    margin-bottom: 0.5rem;

    & .uploaded__file{
      display: flex;
      align-items: center;

      & p:first-child {
        margin-right: 0.35rem;
      }
    }
  }

  & .file__upload {

    & label {
      transition: all .3s ease;
      @include mixinPrimaryButton(
        0.4rem 0.5rem,
        transparent,
        $accentDark,
        1px dashed $accentDark,
        8px
      );

      & svg path,
      & p {
        transition: all .3s ease;
      }

      & > div {
        margin-right: 0.35rem;
      }

      &:hover svg path {
        fill: rgba($accentDark, 0.5);
      }

      &:hover p {
        color: rgba($accentDark, 0.5);
      }

      &:hover {
        border-color: rgba($accentDark, 0.5);
      }
    }
  }
}
