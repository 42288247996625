@import 'styles/vars.scss';

.wrapper {

  &__input {
    
    & input {
      
      &:disabled {
        color: $textTitle;
        font-weight: 500;
      }

      font-size: 1rem;

      @media (max-width: 480px) {
        font-size: 0.8rem;
      }
    }

    & input {
      accent-color: var(--accent);
    }
  }

  &__icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    width: 2.75rem;
    height: 2.75rem;
    background-color: $backgroundGrayAlmostWhite;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}
