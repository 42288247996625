@import 'styles/vars';

.wrapper {
  padding: 1.25rem;
  border: 1px solid $grayFive;
  border-radius: 1rem;
  background-color: $white;

  & .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    //margin-bottom: 1rem;

    & .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $accentDark;
    }

    & p {
      color: $accentDark;
      font-weight: 500;
      font-size: 0.875rem;
    }
  }

  & .kind__wrapper {
    overflow: hidden;
    & .kind {
      margin: 1rem 0;

      & .label {
        font-size: 14px;
        color: $accentDark;
        line-height: 24px;
        font-weight: 500;
      }
    }
  }

  & .date {
    overflow: hidden;
    &__item:not(:last-child) {
      margin-bottom: 1rem;
    }

    & .calendar__starts_btn,
    & .calendar__starts_btn {

    }

  }
}
