@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $graySix;
  width: 100%;

  &__container {
    position: relative;
    padding: 1.875rem 1rem;
    @include mixinAuthContainer(72.125rem);

    & .wrapper__title {
      margin-bottom: 1.5rem;
      & .title {
        color: $accentDark;
        font-size: 1.875rem;
        font-weight: 600;
        margin-right: 1rem;
      }
      & .wrapper__inner__buttons {
        display: flex;
        margin: 1.75rem 0 1.5rem 0;

        & button:first-child {
          margin-right: 1.625rem;
        }

        & .wrapper__inner__button {
          display: flex;
          align-items: center;

          &_active {
            width: 100%;
            height: 3rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: relative;

            p {
              font-size: 1.25rem;
              font-weight: 500;
              color: $textTitle;
            }

            &::before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 3px;
              background: $buttonPrimary;
              border-radius: 1px;
            }
          }

          &_default {
            width: 100%;
            height: 3rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: relative;

            &::before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 3px;
              border-radius: 1px;
              transition: background 0.3s ease;
            }

            p {
              font-size: 1.25rem;
              font-weight: 500;
              color: $grayThree;
            }

            &:hover {
              &::before {
                background: $buttonPrimary;
              }

              p {
                color: $textTitle;
              }
            }
          }
        }
      }

      & button {
        display: flex;

        & svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }

    & form {
      position: relative;
    }

    & .back {
      display: flex;
      align-items: center;
      margin-bottom: 1.375rem;

      &_icon {
        background-color: $buttonPrimary;
        width: 2.25rem;
        height: 2.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        margin-right: 0.75rem;
      }

      &_text {
        & p {
          font-size: 1.25rem;
          font-weight: 500;
          color: $accentDark;
        }
      }

      @media (max-width: 768px) {
        padding: 1.875rem 1rem 0;
        & + div {
          padding: 0 1rem;
          margin-bottom: 10.5rem;
        }
      }

      @media (max-width: 480px) {
        & + div {
          padding: 0 1rem;
          margin-bottom: 14rem;
        }
      }
    }

    & .wrapper__detail {
      display: flex;
      .detail {
        position: relative;
        width: 100%;
        flex: 1;
        z-index: 2;
        margin-right: 1.5rem;

        &__payment {
          &__list {
            & .item {
              background-color: $white;
              border-radius: 1rem;
              padding: 1rem;

              &:not(:last-child) {
                margin-bottom: 0.5rem;
              }

              &__header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 1rem;

                & .item__title_wrapper {
                  
                  &:hover svg path {
                    fill: $accentYellow;
                  }

                  & .item__link {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    & .item__title {
                      color: $accentDark;
  
                      & {
                        transition: color 0.2s ease;
                      }
  
                      &:hover {
                        color: $yellowLight;
                      }
                    }

                    &:hover .item__title {
                      color: $accentYellow;
                    }

                    & .item__icon {
                      display: flex;
                      align-items: center;
                      margin-left: 0.3rem;

                      & svg {
                        width: 0.8rem;
                      }

                      & svg path {
                        transition: fill 0.3s ease;
                      }
                    }
                  }
                }

                & .price {
                  & p {
                    color: $accentDark;
                    font-size: 1.5rem;
                    font-weight: 700;
                  }

                  @media (max-width: 480px) {
                    & p {
                      font-size: 1rem;
                    }
                  }
                }
              }

              &__title {
                font-weight: 700;
                font-size: 1.5rem;
                color: $accentDark;

                @media (max-width: 480px) {
                  font-size: 1rem;
                }
              }

              &__description {
                border: 1px solid $grayFive;
                border-radius: 0.875rem;
                padding: 1rem;
                line-height: 1.5rem;

                &:not(:last-child) {
                  margin-bottom: 1rem;
                }

                & .description__header {
                  margin-bottom: 0.5rem;
                  display: flex;
                  justify-content: space-between;

                  & .item__title_wrapper {

                    &:hover svg path {
                      fill: $accentYellow;
                    }
  
                    & .item__link {
                      display: flex;
                      align-items: center;
                      cursor: pointer;
  
                      & .item__title {
                        color: $accentDark;
  
                        & {
                          transition: color 0.2s ease;
                        }
  
                        &:hover {
                          color: $yellowLight;
                        }
                      }
  
                      &:hover .item__title {
                        color: $accentYellow;
                      }
  
                      & .item__icon {
                        display: flex;
                        align-items: center;
                        margin-left: 0.3rem;
  
                        & svg {
                          width: 0.8rem;
                        }
  
                        & svg path {
                          transition: fill 0.3s ease;
                        }
                      }
                    }

                    
                  }

                  & .price {
                    & p {
                      color: $accentDark;
                      font-size: 1.25rem;
                      font-weight: 700;
                    }

                    @media (max-width: 480px) {
                      & p {
                        font-size: 1rem;
                        width: max-content;
                      }
                    }
                  }

                  & .title {
                    font-size: 1.25rem;
                    font-weight: 500;

                    @media (max-width: 480px) {
                      font-size: 0.9rem;
                    }
                  }
                }

                & .description__text {
                  font-weight: 500;
                  font-size: 0.875rem;
                  color: $grayThree;
                }
              }

              &__description_reverse {
                border: none;
                &:not(:last-child) {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }

      .total__wrapper {
        .total {
          height: 100%;
          width: 16.875rem;
          max-width: 100%;
          position: sticky;
          top: 1.5rem;
          &__inner {
            padding: 1.25rem;
            border: 1px solid $grayFive;
            border-radius: 1rem;
            background-color: $white;
            margin-bottom: 0.5rem;
            & .total__item {
              display: flex;
              width: 100%;
              justify-content: space-between;
              margin-bottom: 0.9375rem;

              & p {
                color: $accentDark;
                font-weight: 500;
                font-size: 0.875rem;
              }
            }

            & .total__item__sum {
              display: flex;
              justify-content: space-between;

              & h3,
              & p {
                font-size: 1rem;
                font-weight: 700;
              }
            }

            & .delimiter {
              width: 100%;
              height: 1px;
              background-color: $grayFour;
              margin-bottom: 0.9375rem;
            }
          }

          & .calendar {
            margin-bottom: 0.5rem;
          }

          &__buttons {
            display: flex;
            flex-direction: column;
            align-items: center;

            & .total__icon {
              display: flex;
              align-items: center;
            }

            & button {
              text-transform: uppercase;
              align-items: center;
              display: flex;
              gap: 0.6875rem;
              @include mixinPrimaryButton(
                0.875rem 3.125rem,
                $accentYellow,
                $accentDark,
                none,
                0.5rem,
                100%,
                2.75rem
              );

              &:disabled {
                background-color: $grayFive;

                & p {
                  color: $grayThree;
                }
              }

              & p {
                font-size: 1rem;
                font-weight: 600;
                width: 100%;
                color: $textTitle;
              }

              & svg path {
                stroke: $accentDark;
              }
            }
          }
        }

        @media (max-width: 768px) {
          display: none;
        }
      }

      @media (max-width: 768px) {
        & .detail {
          margin-right: 0;
        }
      }
    }

    .total__wrapper_mobile {
      display: none;
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 5;
      margin: 0;
      .total {
        border: 1px solid $grayFive;
        border-radius: 1rem;
        background-color: $white;
        padding: 1.25rem;
        width: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &__inner {
          margin-bottom: 1.25rem;
          padding: 0;
          border: none;
          border-radius: 0;
          background-color: transparent;

          & .total__info_icon {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 0.3rem;
            & .total__info_button {
              display: flex;
              @include mixinPrimaryButton(0.375rem 0.625rem, $graySix, transparent, none, 1.375rem);
            }

            & svg {
              transition: transform 0.3s ease;
            }

            & .arrow_rotate {
              & svg {
                transform: rotate(180deg);
              }
            }
          }

          & .total__main_info {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            gap: 0.9375rem;
            & .total__item {
              display: flex;
              width: 100%;
              justify-content: space-between;

              & p {
                color: $accentDark;
                font-weight: 500;
                font-size: 0.875rem;
              }
            }
            & .delimiter {
              width: 100%;
              height: 1px;
              background-color: $grayFour;
              margin-bottom: 0.9375rem;
            }
          }

          & .total__item__sum {
            display: flex;
            justify-content: space-between;

            & h3,
            & p {
              font-size: 1rem;
              font-weight: 700;
            }
          }
        }

        & .calendar__mobile {
          margin-bottom: 0.5rem;
        }

        &__buttons {
          display: flex;
          flex-direction: column;
          align-items: center;

          & .total__icon {
            display: flex;
            align-items: center;
          }

          & button {
            text-transform: uppercase;
            align-items: center;
            display: flex;
            gap: 0.6875rem;
            @include mixinPrimaryButton(0, $accentYellow, $accentDark, none, 0.5rem, 100%, 2.75rem);

            &:disabled {
              background-color: $grayFive;

              & p {
                color: $grayThree;
              }
            }

            & p {
              font-size: 1rem;
              font-weight: 600;
              width: 100%;
              color: $textTitle;
            }

            & svg path {
              stroke: $accentDark;
            }
          }
        }
      }

      @media (max-width: 768px) {
        display: flex;
      }
    }

    @media (max-width: 768px) {
      padding: 0;
    }
  }
}
