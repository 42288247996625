.input {
  display: none;
}

.label {
  display: flex;
  align-items: center;
  width: max-content;
  cursor: pointer;

  & .icon {
    display: flex;
    align-items: center;
    margin-right: 0.75rem;
  }

  & .text {
    font-weight: 500;
    font-size: 1rem;
  }
}
